define('viewmodels/userModule/inHereFailedLogin',['plugins/router', 'durandal/app', 'shell', 'services/security', 'knockout', 'jquery', 'plugins/http', 'global/session', 'config', 'alertNotification', 'migrationModel', 'jquerycapslock', 'services/logger', 'register'],
    function (router, app, shell, security, ko, $, https, session, config, alerter, migrationModel, jquerycapslock, logger, registerModule) {
        var isLoggedIn = ko.observable(false);


        var vm = {
            activate: activate,
            title: 'login',
            session: session,
            loaded: false,
            returnToSignIn: returnToSignIn,
            session: session,
            shell: shell
        };

        return vm;

        function activate() {

        }

        function returnToSignIn() {
            shell.logout();
            window.location = window.location.origin;
        }
    });

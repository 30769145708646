define('viewmodels/userModule/inHereNotApproved',['plugins/router', 'durandal/app', 'services/security', 'shell', 'knockout', 'jquery', 'plugins/http', 'global/session', 'config', 'alertNotification', 'migrationModel', 'jquerycapslock', 'services/logger'],
    function (router, app, security, shell, ko, $, https, session, config, alerter, migrationModel, jquerycapslock, logger) {
        var isLoggedIn = ko.observable(false);


        var vm = {
            title: 'login',
            session: session,
            loaded: false,
            returnToSignIn: returnToSignIn,
            session: session
        };

        return vm;

        function returnToSignIn() {
            shell.logout();
            window.location = window.location.origin;
        }
    });

define('viewmodels/userModule/inHereNotAMember',['plugins/router', 'durandal/app', 'services/security', 'shell', 'knockout', 'jquery', 'plugins/http', 'global/session', 'config', 'alertNotification', 'migrationModel', 'jquerycapslock', 'services/logger', 'register'],
    function (router, app, security, shell, ko, $, https, session, config, alerter, migrationModel, jquerycapslock, logger, registerModule) {
        var isLoggedIn = ko.observable(false);


        var vm = {
            activate: activate,
            title: 'login',
            session: session,
            returnToSignIn: returnToSignIn,
            register: register,
            session: session,
            shell: shell
            
        };

        return vm;

        function activate() {

        }

        function returnToSignIn() {
            shell.logout();
            window.location = window.location.origin;
        }

        function register() {

            registerModule.resetForm();
            router.navigate('register', { replace: true, trigger: true });

        }


    });

define('global/session',['plugins/router', 'services/security', 'knockout', 'Q', 'config', 'plugins/http', 'jquery', 'jquery.utilities', 'alertNotification', 'alertNotificationAtBottomRight', 'orderForPropertyAddressModel', 'deviceDetect', 'utlities', 'durandal/app', 'services/logger'],
    function (router, security, ko, Q, config, https, $, utils, alert, alertAtBottomRight, orderForPropertyAddressModel, deviceDetect, utlities, app, logger)
    {
        var orderForPropertyAddress = new orderForPropertyAddressModel.placeAnOrderForProperty();
        var userProfile = ko.observableArray();
        var managerProfile = ko.observable('');
        var managerTeamProfile = ko.observable('');
        var managerFullName = ko.observable('');
        var fullName = ko.observable('');
        var firstName = ko.observable('');
        var userAddressZip = ko.observable('');
        var userAddressCity = ko.observable('');
        var brandServicesName = ko.observable('');
        var userFIPS = ko.observable('');
        var userAddressStateAbbr = ko.observable('');
        var userCountyLabel = ko.observable('');
        var userAssignedOperationsList = ko.observableArray([]);
        var isSearchFromHeader = ko.observable(false);
        var headerSearchText = ko.observable('').extend({ maxLength: { params: 250, message: '250 or less characters are allowed.' } });
        var showTopSearchControl = ko.observable(true);
        var userId = ko.observable('');
        var isGlobalLoginMessageEnabled = ko.observable(false);
        var hasGlobalLoginMessageDisplayed = ko.observable(false);
        var globalLoginMessageText = ko.observable('');
        var isBKEfarmNavigationMade = ko.observable(false);
        var isEmployee = ko.observable(false);
        var userTypeIsEmployee = ko.observable(false);
        var userDefaultOperationId = ko.observable('');
        var userOperation = ko.observableArray();
        var defaultOperation = ko.observableArray();
        var defaultOpertionId = ko.observable('');
        var isTCPAConsentTrackingEnabledForDefaultOperation = ko.observable(false);
        var defaultOperationName = ko.observable('');
        var brandId = ko.observable('');
        var profileId = ko.observable('');
        var brandName = ko.observable('');
        var isAjaxPost = ko.observable(false);
        var sessionTimeoutVal = ko.observable(0);
        var parcelLineDuration = ko.observable(0);
        var lastAjaxCall = ko.observable(undefined);
        var hasLoggedOut = ko.observable(false);
        var purchasableProdutsShoppingCartList = ko.observableArray([]);
        var purchasableProdutsWalletBalance = ko.observable(0);
        var purchasableProdutsCreditBalance = ko.observable(0);
        var purchasableProdutsWalletEnabled = ko.observable(false);
        var purchasableProdutsCreditsEnabled = ko.observable(false);
        var purchasableProdutsSelectedProperty = ko.observable(undefined);
        var purchasableProdutsECommerceId = ko.observable(0);
        var purchasableProdutsTax = ko.observable(0.0);
        var selectedPropertyId = ko.observable(0);
        var isChatEnable = ko.observable(false);
        var isLoadingBlocked = ko.observable(true);
        var scriptValue = ko.observable('');
        /*var mobileLoginDisableOperations = ko.observableArray([]);*/
        var quickMenuList = ko.observableArray([]);
        var adminMenuList = ko.observableArray([]);
        var favoritesList = ko.observableArray([]);
        var isLogoutVisible = ko.observable(true);
        var limitDocProp = ko.observable('').extend({ notify: 'always' });
        var creditsDocProp = ko.observable('').extend({ notify: 'always' });
        var limitOnlineDoc = ko.observable('').extend({ notify: 'always' });
        var creditsOnlineDoc = ko.observable('').extend({ notify: 'always' });
        var limitLpsDoc = ko.observable('').extend({ notify: 'always' });
        var creditsLpsDoc = ko.observable('').extend({ notify: 'always' });
        var eFarmUrl = ko.observable('');
        var foreclosureSearchUrl = ko.observable('');
        var creditsForeclosureSearch = ko.observable('').extend({ notify: 'always' });
        var limitForeclosureSearch = ko.observable('').extend({ notify: 'always' });
        var leadLocatorUrl = ko.observable('');
        var creditseFarm = ko.observable('').extend({ notify: 'always' });
        var limiteFarm2 = ko.observable('').extend({ notify: 'always' });
        var creditseFarm2 = ko.observable('').extend({ notify: 'always' });
        var limiteFarm3 = ko.observable('').extend({ notify: 'always' });
        var creditseFarm3 = ko.observable('').extend({ notify: 'always' });
        var limiteFarm = ko.observable('').extend({ notify: 'always' });
        var creditsLeadlocator = ko.observable('').extend({ notify: 'always' });
        var limitLeadlocator = ko.observable('').extend({ notify: 'always' });
        var bingMapAPIKey = ko.observable('');
        var bingMapAPIKeyAnonym = ko.observable('');
        var parcelLineKey = ko.observable('');
        var refreshConfigured = ko.observable(false);
        var bypassAuthorize = ko.observable(false);
        var isSalesRepCreatedFirstTimeLogin = ko.observable(false);
        var vcheckuid = ko.observable('');
        var productMenuList = ko.observableArray([]);
        var servicesList = ko.observableArray([]);
        var hasPaidOrders = ko.observable(false);
        var showAddFavButton = ko.observable(true);
        var recentSearches = ko.observableArray([]);
        var customerAddress = ko.observable('');
        var isMapAvailable = ko.observable(true);
        var isSelectedPropertyNeedToBeCentered = ko.observable(true);
        var allCountiesWithFips = ko.observable();
        var isParcelOverlayEnabled = ko.observable(false);
        var stateList = ko.observable(undefined);
        var showPurchasableProducts = ko.observable(undefined);
        var OperationsList = ko.observable(undefined);
        var isICOrder = ko.observable(false);
        var isHighContrastEnabled = ko.observable(false);
        //var isUsageUpdated = ko.observable(true);
        var carouselClasses = ko.observable([]);
        //Online Order History
        var orderRefNumber = ko.observable('');
        var escrowOrderNumber = ko.observable('');
        var titleOrderNumber = ko.observable('');
        var customerName = ko.observable('');
        var propertyAddress = ko.observable('');
        var fromDate = ko.observable('');
        var toDate = ko.observable('');
        var onlineSearchResults = ko.observableArray([]);

        //Offline Order History
        var offlineOrderRefNumber = ko.observable('');
        var offlineCustomerName = ko.observable('');
        var offlineFromDate = ko.observable('');
        var offlineToDate = ko.observable('');
        var offlineSearchResults = ko.observableArray([]);

        //viewOrders - Order History
        var viewOrders_OrderRefNumber = ko.observable('');
        var viewOrders_CustomerName = ko.observable('');
        var viewOrders_FromDate = ko.observable('');
        var viewOrders_ToDate = ko.observable('');
        var viewOrders_SearchResults = ko.observableArray([]);

        //
        var titleProOperationsEnable = ko.observable(false);
        var reisourceOperationsEnable = ko.observable(false);
        var premiumLeadsHomePageEnable = ko.observable(false);

        var accessReiSourceUrl = ko.observable();
        var accessTitleProUrl = ko.observable();
        var slsEnabled = ko.observable(false);
        var registrationsEnabled = ko.observable(true);

        var llaAccepted = ko.observable(false);
        var isCustomer = ko.observable(false);

        var hasAccessReiSourceAccount = ko.observable(true);

        var isZIPCodeServiceDown = ko.observable(false);
        var pendingApprovals = ko.observableArray([]);
        var genericLLABrand = ko.observable('');
        var inHereUpgradeUserEmail = ko.observable('');
        var oktaActivationUrl = ko.observable('');
        var oneTimeCodeUid = ko.observable('');
        var oktaUpgradeStarted = ko.observable(false);
        var oktaIdToken = ko.observable('');

        llaAccepted.subscribe(function (isAccepted)
        {
            sessionStorage["llaAccepted"] = isAccepted;
        });


        var toproutes = ko.observableArray([]);
        var userProfileRoutes = ko.observable();

        var isEZ2ReadCompsEnable = ko.observable(false);
        var isAdminlink = ko.observable(true);
        var enableAddFundsToWallet = ko.observable(false);

        // MP :Iteration 1 : User Story 76826:CP - Add "Recent Requests" in the right navigation
        var recentRequestObj = ko.observableArray([]);
        var recentRequestObjCpUrl = ko.observable('');
        var recentRequestObjName = ko.observable('');
        var toggleSideNav = ko.observable(false);

        var orderId = ko.observable();
        var mode = ko.observable();
        var pass = ko.observable();
        var iframeLoginError = ko.observable("");

        var isPopUpBlocked = ko.observable(false);
        var hasLPSCredit = ko.observable(false);
        var hasAnyProduct = ko.observable(false);
        var selectedProfLanguage = { //profile language
            langageCode: ko.observable(),
            languageText: ko.observable()
        };

        var selectedProduct = ko.observable('');
        var lastLogonDateTime = ko.observable('');

        var userZipInfo = ko.observable(undefined);
        var userCountiesList = ko.observable(undefined);
        var userAPNFormat = ko.observable(undefined);
        var backendSystem = ko.observable('');
        var icUid = ko.observable('');
        var icPass = ko.observable('');
        var savedCart = ko.observable(undefined);
        var getSelectedProfileCss = ko.computed(function ()
        {
            if (selectedProfLanguage != null && selectedProfLanguage.langageCode() != null)
                return selectedProfLanguage.langageCode() == '' ? '-' : selectedProfLanguage.langageCode();
            else
                return '-';
        });

        var recaptchaKey = ko.observable('');

        var activeRoutingContext = ko.observable();
        var isMobile = ko.observable(false);
        var useOktaAuthentication = ko.observable(false);
        var deviceType = ko.observable("desktop");
        var browser = ko.observable("");
        var observeRouterActiveInstruction = ko.computed(function ()
        {
            activeRoutingContext(router.activeInstruction());
            //console.log('context changed');
            return router.activeInstruction();
        });

        var payResponseId = ko.observable("");
        var payResponseAction = ko.observable("");
        var bkSlsParentId = ko.observable("");
        var bkSlsToken = ko.observable("");
        var bkfsUserCreditInfo = ko.observableArray([]);
        var isPromptClassicEfarmMessage = ko.observable(false);
        var promptClassicEfarmMessage = ko.observable('');
        var classicEfarmName = ko.observable('');
        var nextGenEfarmName = ko.observable('');
        var isNgPLeadsEnabled = ko.observable(false);
        var ngPLParentId = ko.observable('');
        var ngPopUpEnabled = ko.observable(false);
        var ngPopUpText = ko.observable('');



        //<UserCreditInfo>
        //    <PackageID>28288</PackageID>
        //    <ProductID>1000</ProductID>
        //    <ProductName>List Generation</ProductName>
        //    <Entitled>0</Entitled>
        //    <Used>0</Used>
        //    <Remaining>0</Remaining>
        //    <HasAccess>Y</HasAccess>
        //    </UserCreditInfo>


        //var hostname = window.location.hostname;
        //var isCTIC = hostname.indexOf("ctic.com");
        //if (isCTIC >= 0) {
        //    $('head').append('<link rel="icon" href="/favicon_CTIC.ico" type="image/x-icon"/>');
        //    $('head').append('<link rel="shortcut icon" href="/favicon_CTIC.ico" type="image/x-icon"/>');
        //    $('head').append('<link rel="apple-touch-icon" href="/apple-touch-icon.png" />');
        //    $('head').append('<link rel="apple-touch-icon" sizes="57x57" href="/apple-touch-icon-57x57.png" />');
        //    $('head').append('<link rel="apple-touch-icon" sizes="72x72" href="/apple-touch-icon-72x72.png" />');
        //    $('head').append('<link rel="apple-touch-icon" sizes="114x114" href="/apple-touch-icon-114x114.png" />');
        //    $('head').append('<link rel="apple-touch-icon" sizes="144x144" href="/apple-touch-icon-144x144.png" />');
        //}

        function buildTopRoutes()
        {

        }


        function restoreSessionStorageFromLocalStorage()
        {
            var backupText = localStorage["sessionStorageBackup"],
                backup;



            if (backupText)
            {
                backup = JSON.parse(backupText);

                for (var key in backup)
                {
                    sessionStorage[key] = backup[key];
                }

                localStorage.removeItem("sessionStorageBackup");
            }
        }

        function setAccessToken(accessToken, persistent)
        {
            //localStorage["accessToken"] = accessToken;
            //sessionStorage["accessToken"] = accessToken;
            //localStorage["asdtyop"] = 'yes';
            sessionStorage.setItem("asdtyop", 'Yes');
            localStorage.setItem("asdtyop", 'Yes');
            if (persistent)
            {
                localStorage["accessToken"] = accessToken;
                localStorage["rememberMe"] = true;
                localStorage["MessageDisplayed"] = false;
            } else
            {
                sessionStorage['initSession'] = 'true';
                sessionStorage["accessToken"] = accessToken;
                localStorage["accessToken"] = accessToken;
                localStorage["rememberMe"] = false;
            }

        }

        function setAccessTokenExpiryTime(TokenExpiryTimeInSeconds)
        {
            if (TokenExpiryTimeInSeconds != undefined && TokenExpiryTimeInSeconds != null)
            {
                var now = (new Date()).getTime();
                var tokenTimeSpan = 1000 * TokenExpiryTimeInSeconds;
                localStorage["accessTokenExpiresOn"] = now + tokenTimeSpan;
            }

        }

        function getAccessToken()
        {
            return sessionStorage["accessToken"] || localStorage["accessToken"];
        }

        function clearAccessToken()
        {

            localStorage["accessToken"] = null;
            localStorage["accessTokenExpiresOn"] = null;
            sessionStorage["accessToken"] = null;
            sessionStorage["asdtyop"] = null;
            localStorage["asdtyop"] = null;
            

            localStorage.removeItem("accessToken");
            localStorage.removeItem("accessTokenExpiresOn");
            sessionStorage.removeItem("asdtyop");
            localStorage.removeItem("asdtyop");
            sessionStorage.removeItem("accessToken");

        }

        function clearOktaAccessTokens() {
            localStorage["okta-cache-storage"] = null;
            localStorage["okta-shared-transaction-storage"] = null;
            localStorage["okta-token-storage"] = null;
            sessionStorage.removeItem("okta-transaction-storage");
            sessionStorage.removeItem("oktaIdToken");
            cookieStore.delete("DT");
            cookieStore.delete("activate_ca_modal_triggered");
            cookieStore.delete("autolaunch_triggered");
            cookieStore.delete("luf_604927246");
            cookieStore.delete("sid");
        }

        function init()
        {
            console.log('session init');
            isBKEfarmNavigationMade(false);

            var urlCurrent = window.location.href;

            if (urlCurrent.indexOf("heritage") > 0) {
                router.navigate('#acceptancella');
            }

            if (urlCurrent.indexOf("llaAcceptancePublic") > 0) {
                console.log('public lla');
                setTimeout(20000);
                //router.navigate('#llaAcceptancePublic');
            }

            restoreSessionStorageFromLocalStorage();
            buildTopRoutes();
            showTopSearchControl(true);

            getOktaAuthenticationEnabled().done(function (result)
            {
                session.useOktaAuthentication(result);
            });

            getBrandServicesName().done(function (result)
            {
                brandServicesName(result);
            });

            getStateList().done(function (states)
            {
                console.log('getStateList from session');
                session.stateList(states);
            });

            //getRegistrationEnabled().done(function (result)
            //{
            //    if (result != undefined && result != null && result)
            //        session.registrationsEnabled(true);
            //    else
            //        session.registrationsEnabled(false);
            //});

            getAppSettings().done(function (result) {
                if (result != undefined && result != null) {
                    if (result.IsRegistrationEnabled != undefined && result.IsRegistrationEnabled != null && result.IsRegistrationEnabled)
                        session.registrationsEnabled(true);
                    else
                        session.registrationsEnabled(false);


                    if (result.RecaptchaKey != undefined && result.RecaptchaKey != null)
                        session.recaptchaKey(result.RecaptchaKey);
                    else
                        session.recaptchaKey('');

                }
            });


        }



        function setRecentSearchesToLocalStorage(recentSearchList)
        {
            //localStorage["recentSearchList"] = recentSearchList;
            localStorage.setItem("recentSearchList", JSON.stringify(recentSearchList));
        }

        function getRecentSearchesFromLocalStorage()
        {
            //return localStorage["recentSearchList"];
            if (localStorage.recentSearchList != undefined && localStorage.recentSearchList != null)
                return JSON.parse(localStorage.recentSearchList);
            else
                return null;
        }



        function getValuecheckProdFromStorage()
        {
            //return localStorage["recentSearchList"];
            if (localStorage.valuecheckProds != undefined && localStorage.valuecheckProds != null)
                return JSON.parse(localStorage.valuecheckProds);
            else
                return null;
        }

        function clearValuecheckProdFromStorage()
        {
            localStorage.removeItem("valuecheckProds");
        }


        function clearRecentSearchesFromLocalStorage()
        {
            localStorage.removeItem("recentSearchList");
        }

        var showProfileButtons = ko.observable(false);
        var isRadiusSearchActive = ko.observable(false)
        init();

        var session = {
            observeRouterActiveInstruction: observeRouterActiveInstruction,
            orderForPropertyAddress: orderForPropertyAddress,
            clearOrderForPropertyAddress: clearOrderForPropertyAddress,
            userName: ko.observable(undefined),
            email: ko.observable(undefined),
            isLoggedIn: ko.observable(false),
            isBusy: ko.observable(false),
            userRoles: ko.observableArray(),
            userBrandId: ko.observable(undefined),
            imgPath: ko.observable(undefined),
            userIsInRole: userIsInRole,
            setUser: setUser,
            clearUser: clearUser,
            isICOrder: isICOrder,
            isLoadingBlocked: isLoadingBlocked,
            archiveSessionStorageToLocalStorage: archiveSessionStorageToLocalStorage,
            isAuthCallback: isAuthCallback,
            userRemembered: userRemembered,
            rememberedToken: rememberedToken,
            userProfile: userProfile,
            fullName: fullName,
            firstName: firstName,
            managerFullName: managerFullName,
            userAddressZip: userAddressZip,
            userAddressCity: userAddressCity,
            userAddressStateAbbr: userAddressStateAbbr,
            userId: userId,
            userOperation: userOperation,
            //isUsageUpdated: isUsageUpdated,
            defaultOperation: defaultOperation,
            defaultOpertionId: defaultOpertionId,
            defaultOperationName: defaultOperationName,
            isTCPAConsentTrackingEnabledForDefaultOperation: isTCPAConsentTrackingEnabledForDefaultOperation,
            userAssignedOperationsList: userAssignedOperationsList,
            brandId: brandId,
            profileId: profileId,
            brandName: brandName,
            isAjaxPost: isAjaxPost,
            sessionTimeoutVal: sessionTimeoutVal,
            parcelLineDuration: parcelLineDuration,
            lastAjaxCall: lastAjaxCall,
            hasLoggedOut: ko.observable(false),
            purchasableProdutsShoppingCartList: purchasableProdutsShoppingCartList,
            purchasableProdutsWalletBalance: purchasableProdutsWalletBalance,
            purchasableProdutsCreditBalance: purchasableProdutsCreditBalance,
            purchasableProdutsWalletEnabled: purchasableProdutsWalletEnabled,
            purchasableProdutsCreditsEnabled: purchasableProdutsCreditsEnabled,
            purchasableProdutsSelectedProperty: purchasableProdutsSelectedProperty,
            purchasableProdutsTax: purchasableProdutsTax,
            selectedPropertyId: selectedPropertyId,
            isChatEnable: isChatEnable,
            scriptValue: scriptValue,
            getChatScript: getChatScript,
            purchasableProdutsECommerceId: purchasableProdutsECommerceId,
            quickMenuList: quickMenuList,
            adminMenuList: adminMenuList,
            favoritesList: favoritesList,
            getQuickMenuItems: getQuickMenuItems,
            isLogoutVisible: isLogoutVisible,
            storeACSFToken: storeACSFToken,
            getACSFToken: getACSFToken,
            clearACSFToken: clearACSFToken,
            getAccessToken: getAccessToken,
            setAccessToken: setAccessToken,
            clearAccessToken: clearAccessToken,
            clearOktaAccessTokens: clearOktaAccessTokens,
            creditsDocProp: creditsDocProp,
            limitDocProp: limitDocProp,
            creditsOnlineDoc: creditsOnlineDoc,
            limitOnlineDoc: limitOnlineDoc,
            limitLpsDoc: limitLpsDoc,
            creditsLpsDoc: creditsLpsDoc,
            //Online Order History
            setOnlineSearchData: setOnlineSearchData,
            orderRefNumber: orderRefNumber,
            escrowOrderNumber: escrowOrderNumber,
            titleOrderNumber: titleOrderNumber,
            customerName: customerName,
            propertyAddress: propertyAddress,
            fromDate: fromDate,
            toDate: toDate,
            onlineSearchResults: onlineSearchResults,
            //Offline Order History
            setOfflineSearchData: setOfflineSearchData,
            offlineOrderRefNumber: offlineOrderRefNumber,
            offlineCustomerName: offlineCustomerName,
            offlineFromDate: offlineFromDate,
            offlineToDate: offlineToDate,
            offlineSearchResults: offlineSearchResults,

            viewOrders_setSearchData: viewOrders_setSearchData,
            viewOrders_OrderRefNumber: viewOrders_OrderRefNumber,
            viewOrders_CustomerName: viewOrders_CustomerName,
            viewOrders_FromDate: viewOrders_FromDate,
            viewOrders_ToDate: viewOrders_ToDate,
            viewOrders_SearchResults: viewOrders_SearchResults,

            eFarmUrl: eFarmUrl,
            foreclosureSearchUrl: foreclosureSearchUrl,
            creditsForeclosureSearch: creditsForeclosureSearch,
            limitForeclosureSearch: limitForeclosureSearch,
            leadLocatorUrl: leadLocatorUrl,
            creditseFarm: creditseFarm,
            limiteFarm: limiteFarm,
            creditseFarm2: creditseFarm2,
            limiteFarm2: limiteFarm2,
            creditseFarm3: creditseFarm3,
            limiteFarm3: limiteFarm3,
            refreshCombinedServiceCreditLimts: refreshCombinedServiceCreditLimts,
            creditsLeadlocator: creditsLeadlocator,
            limitLeadlocator: limitLeadlocator,
            bingMapAPIKey: bingMapAPIKey,
            bingMapAPIKeyAnonym: bingMapAPIKeyAnonym,
            parcelLineKey: parcelLineKey,
            refreshConfigured: refreshConfigured,
            bypassAuthorize: bypassAuthorize,
            isSalesRepCreatedFirstTimeLogin: isSalesRepCreatedFirstTimeLogin,
            recentSearches: recentSearches,
            managerProfile: managerProfile,
            managerTeamProfile: managerTeamProfile,
            clearSessionData: clearSessionData,
            setMapBaseConfigs: setMapBaseConfigs,
            refreshParcelLineToken: refreshParcelLineToken,
            titleProOperationsEnable: titleProOperationsEnable,
            reisourceOperationsEnable: reisourceOperationsEnable,
            premiumLeadsHomePageEnable: premiumLeadsHomePageEnable,
            accessReiSourceUrl: accessReiSourceUrl,
            accessTitleProUrl: accessTitleProUrl,
            isNgPLeadsEnabled: isNgPLeadsEnabled,
            ngPLParentId: ngPLParentId,
            ngPopUpEnabled: ngPopUpEnabled,
            ngPopUpText: ngPopUpText,
            getBrandServicesName: getBrandServicesName,
            brandServicesName: brandServicesName,
            slsEnabled: slsEnabled,
            registrationsEnabled: registrationsEnabled,
            toproutes: toproutes,
            vcheckuid: vcheckuid,
            userProfileRoutes: userProfileRoutes,
            llaAccepted: llaAccepted,
            checkLLAAgreement: checkLLAAgreement,
            showLLAAgreement: showLLAAgreement,
            LLAAcceptClick: LLAAcceptClick,
            LLANotAcceptClick: LLANotAcceptClick,
            isEZ2ReadCompsEnable: isEZ2ReadCompsEnable,
            getOperationUserInfo: getOperationUserInfo,
            isAdminlink: isAdminlink,
            enableAddFundsToWallet: enableAddFundsToWallet,
            getBingMapAPIKey: getBingMapAPIKey,
            getBingMapAPIKeyAnonym: getBingMapAPIKeyAnonym,
            getFavorites: getFavorites,
            productMenuList: productMenuList,
            servicesList: servicesList,
            hasPaidOrders: hasPaidOrders,
            routeToLandingPage: routeToLandingPage,
            fetchSalesExecutiveInfo: fetchSalesExecutiveInfo,
            isCustomer: isCustomer,
            setRecentSearchesToLocalStorage: setRecentSearchesToLocalStorage,
            getRecentSearchesFromLocalStorage: getRecentSearchesFromLocalStorage,
            clearRecentSearchesFromLocalStorage: clearRecentSearchesFromLocalStorage,
            getValuecheckProdFromStorage: getValuecheckProdFromStorage,
            clearValuecheckProdFromStorage: clearValuecheckProdFromStorage,
            orderId: orderId,
            mode: mode,
            iframeLoginError: iframeLoginError,
            noREiSourceAccount: noREiSourceAccount,
            hasAccessReiSourceAccount: hasAccessReiSourceAccount,
            ShowLoading: ShowLoading,
            showLogin: ko.observable(true),// used in registration page
            pass: pass,
            showAddFavButton: showAddFavButton,
            customerAddress: customerAddress,
            lastLogonDateTime: lastLogonDateTime,
            lastLogonSession: lastLogonSession,
            recentRequestObj: recentRequestObj,
            recentRequestObjCpUrl: recentRequestObjCpUrl,
            recentRequestObjName: recentRequestObjName,
            NavLinkClicked: NavLinkClicked,
            isMobile: isMobile,
            deviceType: deviceType,
            browser: browser,
            CheckPopUpBlock: CheckPopUpBlock,
            isPopUpBlocked: isPopUpBlocked,
            bingAdvanceAddressSearch: bingAdvanceAddressSearch,
            hasLPSCredit: hasLPSCredit,
            hasAnyProduct: hasAnyProduct,
            isEmployee: isEmployee,
            selectedProfLanguage: selectedProfLanguage,
            getSelectedProfileCss: getSelectedProfileCss,
            userDefaultOperationId: userDefaultOperationId,
            userTypeIsEmployee: userTypeIsEmployee,
            showProfileButtons: showProfileButtons,
            isRadiusSearchActive: isRadiusSearchActive,
            isSearchFromHeader: isSearchFromHeader,
            headerSearchText: headerSearchText,
            showTopSearchControl: showTopSearchControl,
            getUserInfoByEmail: getUserInfoByEmail,
            isSelectedPropertyNeedToBeCentered: isSelectedPropertyNeedToBeCentered,
            allCountiesWithFips: allCountiesWithFips,
            observableData: {
                contentChanged: ko.observable(false)
            },
            isMapAvailable: isMapAvailable,
            zipCodeServiceDown: zipCodeServiceDown,
            isZIPCodeServiceDown: isZIPCodeServiceDown,
            selectedProduct: selectedProduct,
            userZipInfo: userZipInfo,
            userCountiesList: userCountiesList,
            userAPNFormat: userAPNFormat,
            userFIPS: userFIPS,
            userCountyLabel: userCountyLabel,
            setUserCountyLabel: setUserCountyLabel,
            isParcelOverlayEnabled: isParcelOverlayEnabled,
            stateList: stateList,
            showPurchasableProducts: showPurchasableProducts,
            backendSystem: backendSystem,
            icUid: icUid,
            icPass: icPass,
            activeRoutingContext: activeRoutingContext,
            OperationsList: OperationsList,
            SetSideNavigationHeight: SetSideNavigationHeight,
            toggleSideNav: toggleSideNav,
            detectDeviceType: detectDeviceType,
            detectBrowser: detectBrowser,
            handleFocusOnTabChange: handleFocusOnTabChange,
            handleFocusOnModalInOrder: handleFocusOnModalInOrder,
            isHighContrastEnabled: isHighContrastEnabled,
            carouselClasses: carouselClasses,
            validateInput: validateInput,
            payResponseId: payResponseId,
            payResponseAction: payResponseAction,
            savedCart: savedCart,
            bkSlsParentId: bkSlsParentId,
            bkSlsToken: bkSlsToken,
            //ngSlsToken: ngSlsToken,
            getBkfsUserCreditInfo: getBkfsUserCreditInfo,
            isPromptClassicEfarmMessage: isPromptClassicEfarmMessage,
            promptClassicEfarmMessage: promptClassicEfarmMessage,
            classicEfarmName: classicEfarmName,
            nextGenEfarmName: nextGenEfarmName,
            pendingApprovals: pendingApprovals,
            genericLLABrand: genericLLABrand,
            inHereUpgradeUserEmail: inHereUpgradeUserEmail,
            oktaActivationUrl: oktaActivationUrl,
            useOktaAuthentication: useOktaAuthentication,
            getOktaAuthenticationEnabled: getOktaAuthenticationEnabled,
            getIsGlobalLoginMessageEnabled: getIsGlobalLoginMessageEnabled,
            isGlobalLoginMessageEnabled: isGlobalLoginMessageEnabled,
            hasGlobalLoginMessageDisplayed: hasGlobalLoginMessageDisplayed,
            globalLoginMessageText: globalLoginMessageText,
            isBKEfarmNavigationMade: isBKEfarmNavigationMade,
            getBkSlsAccountInfo: getBkSlsAccountInfo,
            recaptchaKey: recaptchaKey,
            oneTimeCodeUid: oneTimeCodeUid,
            oktaUpgradeStarted: oktaUpgradeStarted,
            oktaIdToken: oktaIdToken
            
        };

        session.userName.subscribe(function (email)
        {
            //if (email != undefined && email != '') {
            //    getUserInfoByEmail(email)

            //}

        })

        return session;

        function checkInputData(text)
        {
            var isInvalid = false;
            if (text.indexOf('<') >= 0)
            {
                //event.preventDefault();
                text = text.replace(/</g, "");
                isInvalid = true;
            }
            if (text.indexOf('>') >= 0)
            {
                //event.preventDefault();
                text = text.replace(/>/g, "");
                isInvalid = true;
            }
            //if (text.indexOf('=') >= 0) {
            //    //event.preventDefault();
            //    text = text.replace(/=/g, "");
            //    isInvalid = true;
            //}
            //if (text.indexOf('"') >= 0) {
            //    //event.preventDefault();
            //    text = text.replace(/"/g, "");
            //    isInvalid = true;
            //}
            //if (text.indexOf('&') >= 0) {
            //    //event.preventDefault();
            //    text = text.replace(/&/g, "");
            //    isInvalid = true;
            //}
            return isInvalid;
        }

        function lastLogonSession()
        {
            if (session.lastLogonDateTime() != '')
            {
                alertAtBottomRight.info(session.lastLogonDateTime(), 'Last Login Date');
                session.lastLogonDateTime('');
            }

        }
        function validateInput()
        {
            //$('input[type=text]:not([data-company])').on("keydown", function (event) {
            //    if ((event.shiftKey && (event.keyCode == 190 || event.keyCode == 188 || event.keyCode == 222 || event.keyCode == 55)) || event.keyCode == 187)
            //    {
            //        alert.error('Invalid character(s).');
            //        event.preventDefault();
            //    }
            //});

            $('input[type=text], textarea').on("keydown", function (event)
            {
                if (event.shiftKey && (event.keyCode == 190 || event.keyCode == 188))
                {
                    alert.error('Invalid character(s).');
                    event.preventDefault();
                }
            });
            //$('input[type=text]:not([data-company])').on("paste", function (event) {
            //    var pastedData = event.originalEvent.clipboardData.getData('text');
            //    var isInvalid = checkInputData(pastedData);
            //    if (isInvalid) {
            //        $(this).val($(this).val()).trigger("change");
            //        alert.error('Invalid character(s).');
            //        event.preventDefault();
            //    }
            //});

            $('input[type=text], textarea').on("paste", function (event)
            {
                var pastedData = event.originalEvent.clipboardData.getData('text');
                var isInvalid = checkInputData(pastedData);
                if (isInvalid)
                {
                    $(this).val($(this).val()).trigger("change");
                    alert.error('Invalid character(s).');
                    event.preventDefault();
                }
            });
            //$('input[type=text]:not([data-company])').blur(function (event) {
            //    var text = $(this).val();
            //    var isInvalid = false;
            //    if (text.indexOf('<') >= 0) {
            //        //event.preventDefault();
            //        text = text.replace(/</g, "");
            //        isInvalid = true;
            //    }
            //    if (text.indexOf('>') >= 0) {
            //        //event.preventDefault();
            //        text = text.replace(/>/g, "");
            //        isInvalid = true;
            //    }
            //    if (text.indexOf('=') >= 0) {
            //        //event.preventDefault();
            //        text = text.replace(/=/g, "");
            //        isInvalid = true;
            //    }
            //    if (text.indexOf('"') >= 0) {
            //        //event.preventDefault();
            //        text = text.replace(/"/g, "");
            //        isInvalid = true;
            //    }
            //    if (text.indexOf('&') >= 0) {
            //        //event.preventDefault();
            //        text = text.replace(/&/g, "");
            //        isInvalid = true;
            //    }
            //    if (isInvalid) {
            //        $(this).val(text).trigger("change");
            //        event.preventDefault();
            //        alert.error('Invalid character(s).');
            //    }
            //});
            $('input[type=text], textarea').blur(function ()
            {
                var text = $(this).val();
                var isInvalid = false;
                if (text.indexOf('<') >= 0)
                {
                    //event.preventDefault();
                    text = text.replace(/</g, "");
                    isInvalid = true;
                }
                if (text.indexOf('>') >= 0)
                {
                    //event.preventDefault();
                    text = text.replace(/>/g, "");
                    isInvalid = true;
                }
                //if (text.indexOf('=') >= 0) {
                //    //event.preventDefault();
                //    text = text.replace(/=/g, "");
                //    isInvalid = true;
                //}
                //if (text.indexOf('"') >= 0) {
                //    //event.preventDefault();
                //    text = text.replace(/"/g, "");
                //    isInvalid = true;
                //}
                //if (text.indexOf('&') >= 0 && !typeIsCompany) {
                //    //event.preventDefault();
                //    text = text.replace(/&/g, "");
                //    isInvalid = true;
                //}
                if (isInvalid)
                {
                    $(this).val(text).trigger("change");
                    alert.error('Invalid character(s).');
                    event.preventDefault();
                }
            });
        }

        function detectDeviceType()
        {
            var agent = navigator.userAgent;
            var dectedDeviceType = deviceDetect.DeviceDetect(agent);
            session.deviceType(dectedDeviceType);
            if (dectedDeviceType === "mobile")
                session.isMobile(true);
            else if ((dectedDeviceType === "tablet") && (window.innerHeight > window.innerWidth))
                session.isMobile(true);
            else if ((dectedDeviceType === "tablet") && (window.innerHeight < window.innerWidth))
                session.isMobile(false);
            else
                session.isMobile(false);
        }

        function detectBrowser()
        {
            var agent = navigator.userAgent;
            //console.log(agent);
            if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1)
            {
                return 'Opera';
            } else if (navigator.userAgent.indexOf("Chrome") !== -1)
            {
                return 'Chrome';
            } else if (navigator.userAgent.indexOf("Safari") !== -1)
            {
                return 'Safari';
            } else if (navigator.userAgent.indexOf("Firefox") !== -1)
            {
                return 'Firefox';
            } else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true))
            {
                return 'IE';//crap
            } else
            {
                return 'Unknown';
            }
        }

        function clearOrderForPropertyAddress()
        {
            session.orderForPropertyAddress.reset();
        }

        function CheckPopUpBlock(href, windowToOpenIn, warningMessageIfPopupBlocked)
        {
            var windowName = 'Reports';
            windowToOpenIn = (windowToOpenIn == null || typeof (windowToOpenIn) == 'undefined') ? "_blank" : windowToOpenIn;
            warningMessageIfPopupBlocked = (warningMessageIfPopupBlocked == null || typeof (warningMessageIfPopupBlocked) == 'undefined') ? "Please disable your popup blocker and try again." : warningMessageIfPopupBlocked;

            var popUp = window.open(href, windowToOpenIn);
            if (popUp == null || typeof (popUp) == 'undefined')
            {
                isPopUpBlocked(true);
                alert.warning(warningMessageIfPopupBlocked);
            }
            else
            {
                isPopUpBlocked(false);
                popUp.focus();
            }
        }

        function NavLinkClicked(clickedURL)
        {
            session.observableData.contentChanged(Date());

            console.log("url Clicked : " + clickedURL);

            var urlCurrent = window.location.href;
            var stopLength = urlCurrent.length;
            if (urlCurrent.indexOf("?") > 0)
            {
                stopLength = urlCurrent.indexOf("?");
            }
            var routeURLCurrent = urlCurrent.substring(urlCurrent.indexOf("#"), stopLength);

            if (session.isLoggedIn())
            {

                if (routeURLCurrent.toLowerCase() == clickedURL.toLowerCase())
                {

                    if (routeURLCurrent.toLowerCase() === "#bingaddresssearch")
                    {
                        if (router && router.activeItem())
                        {
                            router.activeItem().activate(null, null, true);
                            return;
                        }
                    }

                    if (router && router.activeItem())
                    {
                        router.activeItem().activate();
                        return;
                    }
                    else
                    {
                        router.navigate(clickedURL, { replace: false, trigger: true });
                        return;
                    }
                }
                else
                {
                    router.navigate(clickedURL, { replace: false, trigger: true });
                    return;
                }
            }
            return;
        }

        //function isMobile() {
        //    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        //}

        function setMapBaseConfigs(configs)
        {
            if (configs != undefined)
            {
                session.bingMapAPIKey(configs.mapKey);

                if (configs.status != undefined && configs.status.indexOf('Completed') > -1)
                {
                    session.parcelLineKey(configs.parcelLineKey);
                    session.parcelLineDuration(configs.duration);
                    window.document._parcelLineKey = configs.parcelLineKey;

                    if (session.refreshConfigured() == false)
                    {
                        var refreshDuration = session.parcelLineDuration();
                        setTimeout(function ()
                        {
                            refreshDuration = refreshDuration > 5 ? refreshDuration - 5 : refreshDuration;
                            session.refreshConfigured(false);
                            session.refreshParcelLineToken()
                        }, (refreshDuration * 60 * 1000));

                        session.refreshConfigured(true);
                    }
                }
                else
                {
                    console.log(configs.status);
                }
            }
            else
            {
                console.log('Failed to get expected map configuraitons')
            }
            return session.bingMapAPIKey();
        }

        function refreshParcelLineToken()
        {
            console.log('refreshParcelLineToken');
            session.getBingMapAPIKey().done(function (result)
            {
                session.setMapBaseConfigs(result);
            });
        };



        //function setSessionId() {
        //    var url = config.getSessionId;
        //    https.get(url, {
        //                format: 'json'
        //            })
        //    .done(function (data) {

        //   })
        //}

        function getUserInfoByEmail(email)
        {
            var url = config.getUserInfoByEmailUrl;
            userProfile([]);
            // session.fullName('');
            session.isAjaxPost(true);
            https.get(url, {
                email: email,
                format: 'json'
            })
                .then(function (data)
                {
                    if (data.NeedsPasswordChange.toLowerCase() === 'true' && session.useOktaAuthentication() == false)
                    {
                        session.isSalesRepCreatedFirstTimeLogin(true);
                        session.userId = data.UserId;
                        router.navigate('#changePassword');
                        return;
                    }
                    else
                    {
                        session.isSalesRepCreatedFirstTimeLogin(false);
                        session.fullName(data.FirstName + String.fromCharCode(160) + data.LastName);
                        session.firstName(data.FirstName);
                        session.userId = data.UserId;
                        session.userAddressCity(data.City);
                        session.userAddressZip(data.Zip);
                        session.userAddressStateAbbr(data.StateAbbr);
                        session.email(data.Email);
                        //console.log(data)
                        userProfile().push(data);

                        getZipInfo(session.userAddressZip()).done(function (zipInfo)
                        {
                            session.userZipInfo(zipInfo);
                            session.userFIPS(session.userZipInfo().Fips);
                            getAPNFormat(session.userFIPS(), session.userAddressZip()).done(function (APNFormat)
                            {
                                session.userAPNFormat(APNFormat);
                            });
                        });

                        getCounties(session.userAddressStateAbbr()).done(function (counties)
                        {
                            session.userCountiesList(counties);
                        });

                        getOperationsForUser(session.userId).done(function (result)
                        {
                            if (result.DefaultOperation)
                            {
                                session.defaultOpertionId(result.DefaultOperation.OperationId);
                                session.userDefaultOperationId(result.DefaultOperation.OperationId);//Used in User Operation Page
                                session.defaultOperationName(result.DefaultOperation.Name);
                                session.brandId = result.DefaultOperation.BrandId;
                                session.vcheckuid = result.DefaultOperation.VCheckUid;
                                session.slsEnabled(result.DefaultOperation.BKSLSEnabled);
                                session.bkSlsParentId(result.DefaultOperation.BKSLSParentId);
                                session.isNgPLeadsEnabled(result.DefaultOperation.isNgPLeadsEnabled);
                                session.ngPLParentId(result.DefaultOperation.ngPLParentId);
                                session.ngPopUpEnabled(result.DefaultOperation.NgPLeadsPopUpEnabled);
                                session.isTCPAConsentTrackingEnabledForDefaultOperation(result.DefaultOperation.EnableConsentTracking);
                                defaultOperation(result.DefaultOperation);
                                userOperation(result);
                            }
                            else
                            {
                                if (result.Operations.length > 0)
                                {
                                    session.defaultOpertionId(result.Operations[0].OperationId);
                                    session.defaultOperationName(result.Operations[0].Name);
                                    session.brandId = result.Operations[0].BrandId;
                                    session.vcheckuid = result.Operations[0].VCheckUid;
                                    session.isTCPAConsentTrackingEnabledForDefaultOperation(result.Operations[0].EnableConsentTracking);

                                }
                            }

                            if (result.Operations.length > 0)
                            {
                                session.userAssignedOperationsList(result.Operations);
                                //console.log("userAssignedOperationsList ---------------------------------------------- ");
                                //console.log(session.userAssignedOperationsList());
                            }
                            session.isLoggedIn(true);
                            session.hasLoggedOut(false);

                            //setLLAStatus(session.email()).done(function (result) {
                            //    if (result.LiabilityAgreement) {
                            //        if (result.LiabilityAgreement.AgreeStatus === 1) {
                            //            session.llaAccepted(true);
                            //        }
                            //        else {
                            //            session.llaAccepted(false);
                            //        }
                            //    }
                            //});

                            if (data && data.LLAAgreeStatus)
                                session.llaAccepted(data.LLAAgreeStatus == "Agree");

                            getBrandName(session.brandId).done(function (name)
                            {
                                session.brandName(name);
                            })


                            fetchAllCountiesWithFips().done(function (result)
                            {
                                if (result != undefined && result != null && result)
                                {
                                    console.log("fetchAllCountiesWithFips");
                                    var tempResults = result.map(function (element)
                                    {
                                        // JQuery.UI.AutoComplete expects label & value properties, but we can add our own
                                        return {
                                            label: element.Key,
                                            value: element.Value,
                                            // This way we still have acess to the original object
                                            object: element
                                        };
                                    });
                                    session.allCountiesWithFips(tempResults);

                                    setUserCountyLabel();

                                    //console.log(session.allCountiesWithFips());
                                }
                                else
                                    console.log("Failed To fetch fetchAllCountiesWithFips");
                            });

                            getIsParcelEnabled().done(function (result)
                            {
                                session.isParcelOverlayEnabled(result);
                            })

                            getManagerFullName().done(function (result)
                            {
                                session.managerFullName(result);
                            })



                            getQuickMenuItems().done(function (result)
                            {
                                if (session.isLoggedIn() == true)
                                {
                                    quickMenuList(result.MenuItems);
                                    var routes = result.RoutesList;
                                    var quickMenuRoutes = [];
                                    ko.utils.arrayForEach(routes, function (item)
                                    {
                                        quickMenuRoutes.push(
                                            { route: item.Name, title: item.Title, moduleId: item.Module, requiredRoles: item.Roles, type: item.Type, nav: item.IsNav });
                                    });
                                    router.map(quickMenuRoutes).buildNavigationModel();
                                    session.recentRequestObj(ko.utils.arrayFirst(quickMenuList(), function (menuItem)
                                    {
                                        return menuItem.CpUrl === "#recentRequests";
                                    }));

                                    if (session.recentRequestObj() != undefined && session.recentRequestObj() != null)
                                    {
                                        session.recentRequestObjCpUrl(session.recentRequestObj().CpUrl);
                                        session.recentRequestObjName(session.recentRequestObj().Name);
                                        console.log("recentRequestObj");
                                        console.log(session.recentRequestObj());
                                        console.log(session.recentRequestObjName());
                                        console.log(session.recentRequestObjCpUrl());
                                    }
                                }
                            })
                            getAdminMenuItems().done(function (result)
                            {
                                if (session.isLoggedIn() == true)
                                {
                                    adminMenuList(result.MenuItems);
                                    var routes = result.RoutesList;
                                    var adminMenuRoutes = [];
                                    ko.utils.arrayForEach(routes, function (item)
                                    {
                                        adminMenuRoutes.push(
                                            { route: item.Name, title: item.Title, moduleId: item.Module, requiredRoles: item.Roles, type: item.Type, nav: item.IsNav });
                                    });
                                    router.map(adminMenuRoutes).buildNavigationModel();
                                }
                            })
                            getFavorites().done(function (result)
                            {
                                if (session.isLoggedIn() == true)
                                {
                                    favoritesList(result.FavoritesList);
                                    showAddFavButton(result.ShowAddButton);
                                }
                            })
                            getServices().done(function (result)
                            {
                                if (session.isLoggedIn() == true)
                                {
                                    servicesList(result);

                                }
                            })
                            getProductMenuItems().done(function (result)
                            {
                                if (session.isLoggedIn() == true)
                                {
                                    productMenuList(result.menuList);
                                    session.isPromptClassicEfarmMessage(result.isPromptClassicEfarmMessage);
                                    session.promptClassicEfarmMessage(result.promptClassicEfarmMessage);
                                    session.classicEfarmName(result.classicEFarmName);
                                    session.nextGenEfarmName(result.nextGenEFarmName);
                                    var paidOrders = result.menuList.filter(function (item)
                                    {
                                        return (item.CpUrl.indexOf('#paidorder') !== -1);
                                    });
                                    hasPaidOrders(paidOrders && paidOrders.length > 0);

                                    if (hasPaidOrders())
                                        router.map([{ route: 'paidOrder(/:orderType)', title: 'Paid Order', moduleId: 'viewmodels/orderModule/coOEOrder', type: 'DocumentSearch', requiredRoles: ['RegisteredUsers'], nav: true }]).buildNavigationModel();
                                }
                            })

                            getCombinedServiceCreditLimts().done(function (result)
                            {
                                console.log("getCombinedServiceCreditLimts");
                                console.log(result);

                                var item = null;
                                for (var i = 0; i < result.length; i++)
                                {
                                    item = result[i];
                                    if (item.ServiceId == 89)
                                    {
                                        session.limitLpsDoc(item.UsageLimits);
                                        session.creditsLpsDoc(item.Credits);
                                        if (session.limitLpsDoc() > 0)
                                            router.map([{ route: 'documentAddressSearchLps', title: 'Document Search', moduleId: 'viewmodels/documentSearchModule/onlyDocumentNumber', type: 'DocumentSearch', requiredRoles: ['RegisteredUsers'], nav: true, service: item.ServiceId }]).buildNavigationModel();
                                    }

                                    if (item.ServiceId == 92)
                                    {
                                        session.limitOnlineDoc(item.UsageLimits);
                                        session.creditsOnlineDoc(item.Credits);
                                        if (session.limitOnlineDoc() > 0)
                                            router.map([{ route: 'documentAddressSearchTp', title: 'Document Search', moduleId: 'viewmodels/documentSearchModule/onlyDocumentNumber', type: 'DocumentSearch', requiredRoles: ['RegisteredUsers'], nav: true, service: item.ServiceId }]).buildNavigationModel();
                                    }

                                    if (item.ServiceId == 94)
                                    {
                                        session.limitDocProp(item.UsageLimits);
                                        session.creditsDocProp(item.Credits);
                                        if (session.limitDocProp() > 0)
                                            router.map([{ route: 'documentAddressSearch', title: 'Document Search', moduleId: 'viewmodels/documentSearchModule/advanceDocumentSearch', type: 'DocumentSearch', requiredRoles: ['RegisteredUsers'], nav: true, service: item.ServiceId }]).buildNavigationModel();
                                    }

                                    if (item.ServiceId == 3)
                                    {
                                        session.limiteFarm(item.UsageLimits);
                                        session.creditseFarm(item.Credits);
                                    }

                                    if (item.ServiceId == 102)
                                    {
                                        session.limiteFarm2(item.UsageLimits);
                                        session.creditseFarm2(item.Credits);
                                    }

                                    if (item.ServiceId == 103) {
                                        session.limiteFarm3(item.UsageLimits);
                                        session.creditseFarm3(item.Credits);
                                    }

                                    if (item.ServiceId == 4)
                                    {
                                        session.limitForeclosureSearch(item.UsageLimits);
                                        session.creditsForeclosureSearch(item.Credits);
                                    }

                                    if (item.ServiceId == 5)
                                    {
                                        session.limitLeadlocator(item.UsageLimits);
                                        session.creditsLeadlocator(item.Credits);
                                    }


                                }
                            });

                            //getUsageDocForProp().done(function (result) {
                            //    if (session.isLoggedIn() == true) {
                            //        session.limitDocProp(result[0].Value);
                            //        if (session.limitDocProp() > 0)
                            //            router.map([{ route: 'documentAddressSearch', title: 'Document Search', moduleId: 'viewmodels/documentSearchModule/advanceDocumentSearch', type: 'DocumentSearch', requiredRoles: ['RegisteredUsers'], nav: true }]).buildNavigationModel();

                            //        session.creditsDocProp(result[1].Value);
                            //    }
                            //})

                            //geteFarmUrl().done(function (result) {
                            //    if (session.isLoggedIn() == true) {
                            //        session.eFarmUrl(result.UrlAndKey);
                            //        router.map([{ route: 'eFarm', title: 'eFarm', moduleId: 'viewmodels/eFarm/eFarm', nav: true, requiredRoles: ['RegisteredUsers'], nav: true }]).buildNavigationModel();

                            //    }
                            //})

                            //getForeclosureSearchUrl().done(function (result) {
                            //    if (session.isLoggedIn() == true) {
                            //        session.foreclosureSearchUrl(result.UrlAndKey);
                            //        //router.map([{ route: 'foreclosureSearch', title: 'Foreclosure Search', moduleId: 'viewmodels/foreclosureSearch/foreclosureSearch', nav: true, requiredRoles: ['RegisteredUsers'], nav: true }]).buildNavigationModel();

                            //    }
                            //})

                            //getleadLocatorUrl().done(function (result) {
                            //    if (session.isLoggedIn() == true) {
                            //        session.leadLocatorUrl(result.UrlAndKey);
                            //        router.map([{ route: 'leadLocator', title: 'Lead locator', moduleId: 'viewmodels/leadLocator/leadLocator', nav: true, requiredRoles: ['RegisteredUsers'], nav: true }]).buildNavigationModel();
                            //    }
                            //})

                            //getUsageForOnlineDoc().done(function (result) {
                            //    if (session.isLoggedIn() == true) {
                            //        session.limitOnlineDoc(result[0].Value);
                            //        if (session.limitOnlineDoc() > 0)
                            //            router.map([{ route: 'documentAddressSearch', title: 'Document Search', moduleId: 'viewmodels/documentSearchModule/advanceDocumentSearch', type: 'DocumentSearch', requiredRoles: ['RegisteredUsers'], nav: true }]).buildNavigationModel();
                            //        session.creditsOnlineDoc(result[1].Value);
                            //    }
                            //})
                            //getUsageFarm().done(function (result) {
                            //    if (session.isLoggedIn() == true) {
                            //        session.limiteFarm(result[0].Value);
                            //        session.creditseFarm(result[1].Value);
                            //    }
                            //})
                            //getUsageForeclosureSearch().done(function (result) {
                            //    if (session.isLoggedIn() == true) {
                            //        session.limitForeclosureSearch(result[0].Value);
                            //        session.creditsForeclosureSearch(result[1].Value);
                            //    }
                            //})
                            //getUsageLeadlocator().done(function (result) {
                            //    if (session.isLoggedIn() == true) {
                            //        session.limitLeadlocator(result[0].Value);
                            //        session.creditsLeadlocator(result[1].Value);
                            //    }
                            //})
                            getChatScript().done(function (result)
                            {
                                if (session.isLoggedIn() == true)
                                {
                                    scriptValue(result.ChatScript);
                                    isChatEnable(result.EnableChatClient);
                                }
                            });

                            getBingMapAPIKey().done(function (result)
                            {
                                return session.setMapBaseConfigs(result);
                            });


                            getPremiumLeadsStatus(session.defaultOpertionId()).done(function (result)
                            {
                                if (session.isLoggedIn() == true)
                                {
                                    session.titleProOperationsEnable(result.TitleProEnabledForOperation);
                                    session.reisourceOperationsEnable(result.ReiSourceEnabledForOperation);
                                    session.premiumLeadsHomePageEnable(result.PremiumLeadsHomePage);
                                    fetchPremiumLeadsUrls();
                                    //setTimeout(routeToLandingPage(), 1000);
                                    routeToLandingPage();

                                }
                            });

                            getBkSlsAccountInfo().done(function (data)
                            {
                                //if (!result == null && !result == undefined) {
                                if (data != null)
                                {
                                    session.bkSlsToken(data.Token);
                                    //session.isUsageUpdated(data.UsageUpdated);
                                }
                            });


                            ////  for NG Premium Leads
                            //getNgSlsAccountInfo().done(function (data) {
                            //    //if (!result == null && !result == undefined) {
                            //    if (data != null) {
                            //        session.ngSlsToken(data.Token);
                            //        //session.isUsageUpdated(data.UsageUpdated);
                            //    }
                            //})




                            //getEZ2ReadUrlStatusAndUrl(session.defaultOpertionId(), session.serviceId).done(function (result) {
                            //    session.isEZ2ReadCompsEnable(result);
                            //});

                            //IsWalletAvailableForUser().done(function (result) {
                            //    if (result != null && result != undefined) {
                            session.enableAddFundsToWallet(false);
                            //session.enableAddFundsToWallet(result);
                            //}
                            //});

                        });

                        IsUserAEmployee().done(function (result)
                        {
                            if (result != undefined && result != null && result)
                                userTypeIsEmployee(true);
                            else
                                userTypeIsEmployee(false);
                        });

                        getOperationUserInfo(session.userId, session.defaultOpertionId()).done(function (result)
                        {
                            customerAddress(result.StreetAddress1 + ' ' + result.City + ' ' + result.State);
                            session.backendSystem(result.BackendSystem);
                            session.slsEnabled(result.BKSLSEnabled);
                            session.bkSlsParentId(result.BKSLSParentId);
                            session.isNgPLeadsEnabled(result.NgPLeadsEnabled);
                            session.ngPLParentId(result.ngPLParentId);
                            session.ngPopUpEnabled(result.NgPLeadsPopUpEnabled);
                            session.ngPopUpText(result.NgPLeadsPopUpText);
                            if (result.UserTypeId == 5)
                            {
                                isCustomer(true);
                                isAdminlink(false);
                            }
                            else
                            {
                                isAdminlink(true);
                                isCustomer(false);
                            }

                            //// Check [GPS_v2].[dbo].[UserType] table
                            //if (result.UserTypeId == 4 || result.UserTypeId == 6 || result.UserTypeId == 7 || result.UserTypeId == 8 || result.UserTypeId == 9)
                            //    userTypeIsEmployee(true);
                            //else
                            //    userTypeIsEmployee(false);
                        })
                    }
                })

                .fail(Failed)
                .always(function ()
                {
                    session.isAjaxPost(false);
                });
        }

        function setUserCountyLabel()
        {
            if (session.allCountiesWithFips() != undefined && session.userFIPS() != null)
            {
                for (i = 0; i < session.allCountiesWithFips().length; i++)
                {
                    if (session.allCountiesWithFips()[i].value.toLowerCase() === session.userFIPS())
                    {
                        session.userCountyLabel(session.allCountiesWithFips()[i].label);
                        break;
                    }
                }
            }
        }

        function getManagerFullName()
        {
            return utlities.getManagerFullName(session.defaultOpertionId(), session.userId);
        }

        function getZipInfo(zip)
        {
            var url = config.getZipInfoUrl;
            return https.get(url, {
                zipCode: zip,
                format: 'json'
            });
        }

        function getCounties(state)
        {
            var url = config.countyUrl;
            return https.get(url, {
                stateAbbr: state,
                format: 'json'
            });
        }

        function getRegistrationEnabled()
        {
            var url = config.registrationsEnabledUrl;
            return https.get(url, {
                format: 'json'
            });
        }

        //getAppSettings

        function getAppSettings() {
            var url = config.getAppSettingsUrl;
            return https.get(url, {
                format: 'json'
            });
        }

        function getAPNFormat(fips, zip)
        {
            var url = config.getAPNFormatUrl;
            return https.get(url, {
                FIPS: fips,
                Zip: zip,
                format: 'json'
            });
        }


        function fetchAllCountiesWithFips()
        {
            var url = config.getAllCountiesListWithFips;
            return https.get(url, {
                format: 'json'
            });
        }

        function IsUserAEmployee()
        {
            // Check [GPS_v2].[dbo].[UserType] table
            var url = config.isUserAEmployeeURL;
            return https.get(url, {
                format: 'json'
            });
        }

        function IsWalletAvailableForUser()
        {
            var url = config.getIsWalletAvailableForUserUrl;
            return https.get(url, {
                format: 'json'
            });
        }

        function fetchSalesExecutiveInfo()
        {
            var model = require('salesExecutiveModel');
            var salesExecModel = new model.SalesExecutiveModel();
            //return https.get(config.getSalesExecutiveDetails { operationId: session.defaultOpertionId(), userName: session.email })
            return https.get(config.getSalesExecutiveDetails)
                .then(function (data)
                {
                    if (data != null)
                    {
                        salesExecModel.IsSalesExecutiveAvailable(true);
                        salesExecModel.FullName(data.FirstName + " " + data.LastName);
                        salesExecModel.Email(data.EmailAddress);
                        salesExecModel.Phone(data.PhoneWork);
                        salesExecModel.Address(data.StreetAddress1 + " " + data.StreetAddress2);
                        salesExecModel.City(data.City);
                        salesExecModel.Zip(data.Zip);
                        salesExecModel.PhotoUrl(data.PhotoUrl);
                        salesExecModel.UserType(data.UserType);
                        if (data.PhoneWorkExt)
                        {
                            salesExecModel.PhoneExt(data.PhoneWorkExt);
                        }

                        return salesExecModel;
                    }
                    else
                    {
                        return null;
                    }
                })
                .fail(function ()
                {
                    return null;
                })

        }

        function setLLAStatus(email)
        {
            return https.get(config.getLLAByUserId, {
                emailAddress: email,
                format: 'json'
            });
        }

        function getPremiumLeadsStatus(operationId)
        {
            session.premiumLeadsHomePageEnable(false);
            return https.get(config.getPremiumLeadsOperationStatus, {
                operationId: operationId,
                format: 'json'
            });
        }

        function getBrandServicesName()
        {
            var bId = $('.currentBrand').val();
            var url = config.getBrandServicesNameUrl
            return https.get(url, {
                brandId: bId,
                format: 'json'
            });
        }

        function getBkSlsAccountInfo()
        {
            return https.get(config.getGetTitleProAccountInfo,
                {
                }).done(function (data)
                {
                    //}).then(function (data) {
                    if (data != null)
                    {
                        session.bkSlsToken(data.Token);
                        //session.isUsageUpdated(data.UsageUpdated);
                    }


                });


        }

        //function getNgSlsAccountInfo() {
        //    return https.get(config.getAccessPremLeadSlsInfo,
        //        {
        //        }).done(function (data) {
        //        //}).then(function (data) {
        //        if (data != null) {
        //            session.ngSlsToken(data.Token);
        //            //session.isUsageUpdated(data.UsageUpdated);
        //        }


        //    });


        //}



        function refreshCombinedServiceCreditLimts()
        {
            var url = config.getCombinedServiceCreditLimtsUrl;
            return https.get(url,
                {
                    "ParentId": session.bkSlsParentId(),
                    "Token": session.bkSlsToken()
                }).done(function (result)
                {
                    var item = null;
                    for (var i = 0; i < result.length; i++)
                    {
                        item = result[i];

                        if (item.ServiceId == 92)
                        {
                            session.limitOnlineDoc(item.UsageLimits);
                            session.creditsOnlineDoc(item.Credits);
                        }

                        if (item.ServiceId == 94)
                        {
                            session.limitDocProp(item.UsageLimits);
                            session.creditsDocProp(item.Credits);
                        }

                        if (item.ServiceId == 3)
                        {
                            session.limiteFarm(item.UsageLimits);
                            session.creditseFarm(item.Credits);
                        }

                        if (item.ServiceId == 102)
                        {
                            session.limiteFarm2(item.UsageLimits);
                            session.creditseFarm2(item.Credits);
                        }

                        if (item.ServiceId == 103) {
                            session.limiteFarm3(item.UsageLimits);
                            session.creditseFarm3(item.Credits);
                        }

                        if (item.ServiceId == 4)
                        {
                            session.limitForeclosureSearch(item.UsageLimits);
                            session.creditsForeclosureSearch(item.Credits);
                        }

                        if (item.ServiceId == 5)
                        {
                            session.limitLeadlocator(item.UsageLimits);
                            session.creditsLeadlocator(item.Credits);
                        }
                    }
                });
        }

        function getEZ2ReadUrlStatusAndUrl(operationId, serviceId)
        {
            return https.get(config.getPremiumLeadsOperationStatus, {
                operationId: operationId,
                serviceId: serviceId,
                format: 'json'
            });
        }

        function fetchPremiumLeadsUrls()
        {
            //fetchTitleProAccessUrl();
            //fetchReiSourceAccessUrl();
        }

        function fetchTitleProAccessUrl()
        {
            if (session.titleProOperationsEnable() && session.llaAccepted())
            {
                https.get(config.getAccessTitleProAccount, {
                    operationId: session.defaultOpertionId(),
                    brandId: session.brandId,
                    format: 'json'
                }).done(function (data)
                {
                    session.accessTitleProUrl(data.UrlAndKey);
                });
            }
        }

        function fetchReiSourceAccessUrl()
        {
            if (session.reisourceOperationsEnable() && session.llaAccepted())
            {
                https.get(config.getAccessReiSourceAccount, {
                    operationId: session.defaultOpertionId(),
                    brandId: session.brandId,
                    format: 'json'
                }).done(function (data)
                {
                    session.accessReiSourceUrl(data.ProcessedUrl);
                    session.hasAccessReiSourceAccount(!data.HasErrors);
                });
            }
        }

        function noREiSourceAccount()
        {
            //alert.error("Access REiSource Leads -- No Accounts Available. Please contact Your Sales Executive");
            app.showMessage('Required URL is not configured. Please contact Administrator.', 'URL not available', ['OK']);
        }

        function bingAdvanceAddressSearch()
        {
            var urlInComing = window.location.href;
            var stopLength = urlInComing.length;
            if (urlInComing.indexOf("?") > 0)
            {
                stopLength = urlInComing.indexOf("?");
            }
            var routeURL = urlInComing.substring(urlInComing.indexOf("#"), stopLength);

            if (session.isLoggedIn())
            {
                if (routeURL.toLowerCase() === "#bingaddresssearch/true")
                {
                    if (router && router.activeItem())
                        router.activeItem().activate("true");
                }
                else
                    router.navigate('#bingAddressSearch/true', { replace: false, trigger: true });
                return;
            }
        }

        function routeToLandingPage()
        {
           // console.log('Premium leads enabled : ' + session.premiumLeadsHomePageEnable());
            //As per bug 95798, made Landing page to be configured through Admin field than brand based.
            //if (session.brandId === 5 || session.brandId === 21) {

            //    if (session.orderId() != null) {
            //        if (session.mode() == 'offline') {
            //            router.navigate('#viewOfflineOrderDetail' + '/' + session.orderId(), { replace: false, trigger: true });
            //            return;
            //        }
            //        else if (session.mode() == 'online') {
            //            router.navigate('#viewOnlineOrders' + '/' + session.orderId(), { replace: false, trigger: true });
            //            return;
            //        }
            //    }

            //    router.navigate('#premiumLeads', {
            //        replace: false, trigger: true
            //    });
            //        return;
            //}



            if (session.isLoggedIn())
            {
                if (session.orderId() != null)
                {
                    if (session.mode() == 'offline')
                    {
                        router.navigate('#viewOfflineOrderDetail' + '/' + session.orderId(), { replace: false, trigger: true });
                        return;
                    }
                    else if (session.mode() == 'online')
                    {
                        router.navigate('#viewOrders' + '/' + session.orderId(), { replace: false, trigger: true });
                        return;
                    }
                }
                if (session.mode() == 'orders')
                {
                    router.navigate('#orders', { replace: false, trigger: true });
                    return;
                }
            }


            var urlInComing = window.location.href;
            var stopLength = urlInComing.length;
            if (urlInComing.indexOf("?") > 0)
            {
                stopLength = urlInComing.indexOf("?");
            }
            var routeURL = urlInComing.substring(urlInComing.indexOf("#"), stopLength);
            //if (routeURL.toLowerCase().indexOf("#viewofflineorderdetail") >= 0) {
            //    if (session.isLoggedIn()) {
            //        return;
            //    }
            //}
            //if (routeURL.toLowerCase().indexOf("#viewOnlineOrders") >= 0) {
            //    if (session.isLoggedIn()) {
            //        return;
            //    }
            //}
            if (session.isLoggedIn())
            {

                if (session.premiumLeadsHomePageEnable()) {
                    if (routeURL.toLowerCase() === "#premiumleads") {
                        if (router && router.activeItem()) {
                            router.activeItem().activate(null, null, true);
                            return;
                        }
                    }
                    else {
                        router.navigate('#premiumLeads', { replace: false, trigger: true });
                        return;
                    }
                }

                if (routeURL.indexOf("/") > 0)
                {
                    var stopLength = routeURL.indexOf("/");
                    var targetRouteURL = routeURL.substring(0, stopLength);
                    if (targetRouteURL.toLowerCase() === "#businesscard" || targetRouteURL.toLowerCase() === "#valuecheckcallback" || targetRouteURL.toLowerCase() === "#corecallback" || targetRouteURL.toLowerCase() === "#viewonlineorders" || targetRouteURL.toLowerCase() === "#vieworders" || targetRouteURL.toLowerCase() === "#tcpaconsent")
                    {
                        router.navigate(routeURL, { replace: false, trigger: true });
                        return;
                    }
                    else
                    {
                        router.navigate('#bingAddressSearch', { replace: false, trigger: true });
                        return;
                    }
                }
                else
                {
                    
                    if (routeURL.toLowerCase() === "#orders")
                    {
                        if (router && router.activeItem())
                            router.activeItem().activate(null, null, true);
                    }
                    else if (routeURL.toLowerCase() === "#bingaddresssearch")
                    {
                        if (router && router.activeItem())
                            router.activeItem().activate(null, null, true);
                    }
                    else
                    {
                        router.navigate('#bingAddressSearch', { replace: false, trigger: true });
                    }
                }
                return;
            }
            else
            {

                var urlCurrent = window.location.href;

                if (urlCurrent.indexOf("heritage") > 0)
                {
                    router.navigate('#acceptancella');
                }

                if (routeURL.toLowerCase() == "#inhereupgrade2")
                {
                    /*router.navigate('#login', { replace: false, trigger: true });*/
                    window.location = window.location.origin;
                }


                if (routeURL.toLowerCase() != "#register" || routeURL.toLowerCase() != "#iframelogin" || routeURL.toLowerCase() != "#acceptancella" || routeURL.toLowerCase() != "#forgotpassword" || !(routeURL.toLowerCase().indexOf("#register") >= 0) || !(routeURL.toLowerCase().indexOf("#mobileapp") >= 0) || !(routeURL.toLowerCase().indexOf("#calculators") >= 0) || routeURL.toLowerCase() != "#officelocator")
                    router.navigate('#login', { replace: false, trigger: true });
            }
            //if (session != null && session != undefined && session.isLoggedIn() != null && session.isLoggedIn() != undefined && session.isLoggedIn() === false) {
            //    // if you make changes here in if condition, you need to make changes in https.js as well
            //    if (routeURL.toLowerCase() != "#register" && routeURL.toLowerCase() != "#iframelogin" && routeURL.toLowerCase() != "#acceptancella" && routeURL.toLowerCase() != "#forgotpassword" && !(routeURL.toLowerCase().indexOf("#mobileapp") >= 0) && !(routeURL.toLowerCase().indexOf("#calculators") >= 0) && routeURL.toLowerCase() != "#officelocator" && !(routeURL.toLowerCase().indexOf("#viewOfflineOrderDetail") >= 0) && !(routeURL.toLowerCase().indexOf("#viewOnlineOrders") >= 0))
            //        router.navigate('#login', { replace: false, trigger: true });
            //    return;
            //}
        }

        function getChatScript()
        {
            var url = config.getChatClientScriptUrl
            return https.get(url, {
                UserId: session.userId,
                OperationId: session.defaultOpertionId(),
                BrandId: session.brandId,
                format: 'json'
            });
        }
        function getQuickMenuItems()
        {
            var url = config.getQuickMenuItemsUrl
            return https.get(url, {
                userId: session.userId,
                operationId: session.defaultOpertionId(),
                brandId: session.brandId,
                format: 'json'
            });
        }
        function getAdminMenuItems()
        {
            var url = config.getAdminMenuItemsUrl
            return https.get(url, {
                userId: session.userId,
                operationId: session.defaultOpertionId(),
                brandId: session.brandId,
                format: 'json'
            });
        }
        function getBrandName()
        {
            var url = config.getBrandServicesNameUrl
            return https.get(url, {
                brandId: session.brandId,
                format: 'json'
            });
        }



        function getFavorites()
        {
            var url = config.getFavoritesUrl
            return https.get(url, {
                userId: session.userId,
                //operationId: session.defaultOpertionId(),
                //brandId: session.brandId,
                format: 'json'
            });
        }
        function getServices()
        {
            var url = config.getServicesUrl
            return https.get(url, {
                //userId: session.userId,
                //operationId: session.defaultOpertionId(),
                //brandId: session.brandId,
                format: 'json'
            });
        }

        function getBkfsUserCreditInfo()
        {
            var url = config.getBkfsUserCreditInfoUrl;
            return https.get(url,
                {
                    format: 'json'
                });
        }

        function removeRoutes(filterType)
        {
            var routes = router.routes;
            var staticRoutes = routes.filter(function (item)
            {
                return (item.type != filterType);
            });
            router.routes = [];
            router.map(staticRoutes).buildNavigationModel();
        }

        //function getUsageDocForProp() {
        //    var url = config.getUsageUrl
        //    return https.get(url, {
        //        userId: session.userId,
        //        operationId: session.defaultOpertionId(),
        //        serviceId: '94',
        //        format: 'json'
        //    });
        //}

        function getProductMenuItems()
        {
            var url = config.getProductMenuItemsUrl
            return https.get(url, {
                format: 'json'
            });
        }

        //function getUsageFarm() {
        //    var url = config.getUsageUrl
        //    return https.get(url, {
        //        userId: session.userId,
        //        operationId: session.defaultOpertionId(),
        //        serviceId: '3',
        //        format: 'json'
        //    });
        //}

        //function getUsageForeclosureSearch() {
        //    var url = config.getUsageUrl
        //    return https.get(url, {
        //        userId: session.userId,
        //        operationId: session.defaultOpertionId(),
        //        serviceId: '4',
        //        format: 'json'
        //    });
        //}

        //function getUsageLeadlocator() {
        //    var url = config.getUsageUrl
        //    return https.get(url, {
        //        userId: session.userId,
        //        operationId: session.defaultOpertionId(),
        //        serviceId: '5',
        //        format: 'json'
        //    });
        //}

        function geteFarmUrl()
        {
            var url = config.GetUrlAndKeyForFnds
            return https.get(url, {
                userId: session.userId,
                operationId: session.defaultOpertionId(),
                brandId: session.brandId,
                serviceId: '3',
                format: 'json'
            });
        }

        function getForeclosureSearchUrl()
        {
            var url = config.GetUrlAndKeyForFnds
            return https.get(url, {
                userId: session.userId,
                operationId: session.defaultOpertionId(),
                brandId: session.brandId,
                serviceId: '4',
                format: 'json'
            });
        }


        function getBingMapAPIKey()
        {
            return https.get(config.getBingMapAPIKeyUrl);
        }


        function getBingMapAPIKeyAnonym()
        {
            return https.get(config.getBingMapAPIKeyAnonymUrl, null, null, null, null, null, true);
        }

        function getleadLocatorUrl()
        {
            var url = config.GetUrlAndKeyForFnds
            return https.get(url, {
                userId: session.userId,
                operationId: session.defaultOpertionId(),
                brandId: session.brandId,
                serviceId: '5',
                format: 'json'
            });
        }

        //function getUsageForOnlineDoc() {
        //    var url = config.getUsageUrl
        //    return https.get(url, {
        //        userId: session.userId,
        //        operationId: session.defaultOpertionId(),
        //        serviceId: '92',
        //        format: 'json'
        //    });
        //}

        function getOperationsForUser(userId)
        {
            var url = config.getOperationsForUserUrl;
            return https.get(url);

        }

        function getCombinedServiceCreditLimts()
        {
            var url = config.getCombinedServiceCreditLimtsUrl;
            return https.get(url, {
                "ParentId": session.bkSlsParentId(),
                "Token": session.bkSlsToken()
            });
        }
        //session.sessionTimeoutVal(10);
        function getSessionTimeoutVal()
        {
            var url = config.getSessionTimeoutValueUrl;
            var time = 0;
            //console.log('url = ' + url);
            //console.log(https.get(url));
            //return https.get(url);

            https.get(url, {

                format: 'json'
            })
                .then(function (data)
                {
                    //console.log(data);
                    sessionTimeoutVal(data);
                });

        }

        function getOperationUserInfo(userId, operationId)
        {
            var url = config.getOperationUserInfoUrl;
            return https.get(url, {
                userId: userId,
                operationId: 0,
                format: 'json'
            })

        }
        function Failed(error)
        {
            logger.log({
                message: "Unbale to retrive user profile from service.",
                data: null,
                showToast: false,
                type: "error"
            });
        }

        function setUser(user, remember, brand, pwd)
        {
            if (user)
            {

                session.userName(user.userName);
                session.email(user.email);
                session.userBrandId(brand);
                session.fullName();
                session.pass(pwd)
                session.isEmployee(user.IsEmployee);
                isSelectedPropertyNeedToBeCentered(true);
                if (window.location.pathname.toLowerCase().indexOf("ticor") > -1)
                {
                    session.imgPath('Content/images/Brands/TicorTitle.png');
                }
                else
                {
                    session.imgPath('Content/images/Brands/ChicagoTitle.png');
                }

                //if (brand == 1) {
                //     session.imgPath('Content/images/Brands/ChicagoTitle.png');
                //}
                //else {
                //     session.imgPath('Content/images/Brands/TicorTitle.png');
                //}

                if (user.hasOwnProperty("accessToken"))
                {
                    setAccessToken(user.accessToken, remember);
                }
                else if (user.hasOwnProperty("access_token"))
                {
                    setAccessToken(user.access_token, remember);
                }


                if (remember)
                {
                    setAccessTokenExpiryTime(user.expires_in);
                } else
                {
                    setAccessTokenExpiryTime(user.expires_in);
                }

                var roles = user.userRoles.split(",");

                $.each(roles, function (i, v)
                {
                    session.userRoles.push(v);
                });

                var test1 = getSessionTimeoutVal();
                console.log('getSessionTimeoutVal() = ' + test1);

                getUserInfoByEmail(user.userName);


                //session.sessionTimeoutVal(getSessionTimeoutVal());
                //console.log(session.sessionTimeoutVal());
            }

        }
        function setOnlineSearchData(param, searchResults)
        {
            session.orderRefNumber(param.OrderId);
            session.escrowOrderNumber(param.EscrowOrderId);
            session.titleOrderNumber(param.TitleOrderId);
            session.customerName(param.UserId);
            session.propertyAddress(param.PropertyAddress);
            session.fromDate(param.OrderDateFrom);
            session.toDate(param.OrderDateTo);
            session.onlineSearchResults(searchResults);
        }

        function setOfflineSearchData(param, searchResults)
        {
            session.offlineOrderRefNumber(param.OrderId());
            session.offlineCustomerName(param.UserId());
            session.offlineFromDate(param.OrderDateFrom());
            session.offlineToDate(param.OrderDateTo());
            session.offlineSearchResults(searchResults);
        }

        function viewOrders_setSearchData(param, searchResults)
        {
            session.viewOrders_OrderRefNumber(param.OrderId());
            session.viewOrders_CustomerName(param.UserId());
            session.viewOrders_FromDate(param.OrderDateFrom());
            session.viewOrders_ToDate(param.OrderDateTo());
            session.viewOrders_SearchResults(searchResults);
        }

        function clear_viewOrders_setSearchData()
        {
            session.viewOrders_OrderRefNumber('');
            session.viewOrders_CustomerName('');
            session.viewOrders_FromDate('');
            session.viewOrders_ToDate('');
            session.viewOrders_SearchResults([]);
        }

        function clearUser()
        {
            clearAccessToken();
            session.userName('');
            session.email('');
            session.userRoles.removeAll();
            session.recentSearches.removeAll();
            session.isLoggedIn(false);
            session.isAdminlink(true);
            session.enableAddFundsToWallet(false);
            session.headerSearchText('');
            session.backendSystem('');
            session.bkSlsToken('');
            //session.ngSlsToken('');
            session.creditseFarm2('');
            session.limiteFarm2('');
            session.creditseFarm3('');
            session.limiteFarm3('');
            session.bkSlsParentId('');
            session.ngPLParentId('');
            session.ngPopUpEnabled(false);
            session.ngPopUpText('');
            clear_viewOrders_setSearchData();
        }


        function userIsInRole(requiredRole)
        {
            if (requiredRole === undefined)
            {
                return true;
            } else if (session.userRoles() === undefined)
            {
                return false;
            } else
            {
                if ($.isArray(requiredRole))
                {
                    if (requiredRole.length === 0)
                    {
                        return true;
                    } else
                    {
                        return $.arrayIntersect(session.userRoles(), requiredRole).length > 0;
                    }
                } else
                {
                    return $.inArray(requiredRole, session.userRoles()) > -1;
                }
            }
        }

        function isAuthCallback()
        {
            return sessionStorage["associatingExternalLogin"] || sessionStorage["externalLogin"];
        }

        function userRemembered()
        {
            return sessionStorage["accessToken"] !== undefined || localStorage["accessToken"] !== undefined;
        }

        function rememberedToken()
        {
            return sessionStorage["accessToken"] || localStorage["accessToken"];
        }

        function redirectCallback(redirectToManage)
        {
            if (redirectToManage)
            {
                router.navigate('#/manage', 'replace');
            } else
            {
                router.navigate('#/', 'replace');
            }
        }

        function archiveSessionStorageToLocalStorage()
        {
            var backup = {};

            for (var i = 0; i < sessionStorage.length; i++)
            {
                backup[sessionStorage.key(i)] = sessionStorage[sessionStorage.key(i)];
            }

            localStorage["sessionStorageBackup"] = JSON.stringify(backup);
            sessionStorage.clear();
        }

        function storeACSFToken(tokenValue)
        {
            sessionStorage["token"] = tokenValue;
        }

        function getACSFToken()
        {
            return sessionStorage["token"];
        }

        function clearACSFToken()
        {
            sessionStorage["token"] = null;
            sessionStorage.removeItem("token");
        }

        function clearSessionData()
        {
            session.orderRefNumber('');
            session.escrowOrderNumber('');
            session.titleOrderNumber('');
            session.customerName('');
            session.propertyAddress('');
            session.fromDate('');
            session.toDate('');
            session.onlineSearchResults('');
            session.offlineOrderRefNumber('');
            session.offlineCustomerName('');
            session.offlineFromDate('');
            session.offlineToDate('');
            session.offlineSearchResults('');
            session.orderId(null);
            session.backendSystem('');
            session.icUid('');
            session.icPass('');
            session.isGlobalLoginMessageEnabled(false);
            session.globalLoginMessageText('');
        }

        function getIsParcelEnabled()
        {
            return https.get(config.getIsParcelOverlayEnabledUrl);
        }

        function getOktaAuthenticationEnabled()
        {
            return https.get(config.getUseOktaAuthenticationUrl);
        }

        function getIsGlobalLoginMessageEnabled()
        {
            return https.get(config.getIsGlobalLoginMessageEnabledUrl);
        }

        function getStateList()
        {

            //if (session.stateList() != undefined && session.stateList().length>0) {
            //    return;
            //}

            var url = config.stateUrl;
            return https.get(url, {
                stateCode: true, format: 'json'
            });
        }

        function checkLLAAgreement()
        {
            if (!session.llaAccepted())
            {
                session.showLLAAgreement();
                return false;
            }
            return true;
        }

        function showLLAAgreement()
        {
            $('#mdLLAAgreementWithAgreeDisagree').modal('show');
            return false;
        }

        function LLAAcceptClick()
        {
            saveLLAStatus(true);
            return false;
        }

        function LLANotAcceptClick()
        {
            saveLLAStatus(false);
            router.navigate('#');
            return false;
        }



        function ShowLoading()
        {
            $("#mdLoading").modal({ backdrop: 'static', keyboard: false });
            isAjaxPost(true);
        }

        function saveLLAStatus(status)
        {
            var request;
            if (status)
            {
                request = { 'AgreeStatus': 'Agree' };
            }
            else
            {
                request = { 'AgreeStatus': 'NotAgree' };
            }
            https.postSync(config.postUserLLAUrl, request)
                .done(function (data)
                {
                    if (data.HasErrors != false)
                    {
                        session.llaAccepted(false);
                        if (data.Messages["0"].Message)
                        {
                            alert.error(data.Messages["0"].Message);
                        }
                    }
                    else
                    {
                        if (data.LiabilityAgreement.AgreeStatus === 1)
                        {
                            session.llaAccepted(true);
                            fetchPremiumLeadsUrls();
                        }
                        else
                        {
                            session.llaAccepted(false);
                        }
                    }
                    $('#mdLLAAgreementWithAgreeDisagree').modal('hide');
                }).fail(function ()
                {
                    session.llaAccepted(false);
                    $('#mdLLAAgreementWithAgreeDisagree').modal('hide');
                    alert.error("Liability agreement status wasn't saved. Please contact administrator");
                });
        }


        function zipCodeServiceDown()
        {
            //if (session.isZIPCodeServiceDown() === false)
            alert.error("We are having issues with validating Zipcode");
        }

        function handleFocusOnModalInOrder(id)
        {

            setTimeout(function ()
            {
                $('a[data-toggle="tab"]').on('shown.bs.tab', function (e)
                {
                    e.target // newly activated tab
                    e.relatedTarget // previous active tab



                })
            }, 300);

        }

        function handleFocusOnTabChange(ModelId, ViewName)
        {
            var isFoundInitialCtl = false;
            var targetCtl = null;
            setTimeout(function ()
            {
                console.log("Set Focus");
                session.validateInput();
                //setTimeout(function () {

                //}, 200);

                if (!session.isMobile())
                {
                    window.scrollTo(500, 125);
                }

                $('.modalFix').on('hidden.bs.modal', function (e)
                {
                    if ($('button:visible', '.viewSection').length > 0)
                    {
                        $('button:visible', '.viewSection').eq(0).focus();
                    }
                    else
                    {
                        $('button:visible', ".order-page-footer").eq(0).focus();
                    }
                });

                $('.modalFix').on('shown.bs.modal', function (e)
                {
                    console.log('Modal opened');
                    console.log(e);
                    var modalID = '"#' + e.currentTarget.id + '"';
                    if ("mdDeleteItem" == e.currentTarget.id)
                    {
                        $('button:visible', ".modal-footer").eq(0).focus();
                    }
                    else if ("mdAddOrder" == e.currentTarget.id)
                    {
                        if ($('#tblRecentSearches').length > 0 && $('#tblRecentSearches  tr').length >= 2)
                        {
                            targetCtl = $('#tblRecentSearches  tr').eq(1).focus();
                            return;
                        }
                    }
                    else if ("mdCreateLender" == e.currentTarget.id)
                    {
                        $("select:visible", "#" + e.currentTarget.id).eq(0).focus();
                    }
                    else
                    {
                        $("input", "#" + e.currentTarget.id).eq(0).focus();
                    }
                });

                if ($('#tblRecentSearches').length > 0 && $('#tblRecentSearches  tr').length >= 2)
                {
                    targetCtl = $('#tblRecentSearches  tr').eq(1).focus();
                    return;
                }

                if (ModelId == "PropertyInformation")
                {
                    targetCtl = $('input:visible,textarea:visible', '.viewSection');
                }
                else if (ModelId == "Lender" || ModelId == "orderInformation" || ModelId == "SubjectProperty" || ModelId == "listingAgent" || ModelId == "sellingAgent" || ModelId == "endorsement")
                {
                    targetCtl = $("select:visible", '.viewSection');
                }
                else if (ModelId == "COOEOrder")
                {
                    targetCtl = $("#btnAddOrderItem");
                }
                else if (ModelId == "wesbborrowers" || ModelId == "principalSellers" || ModelId == "Lenders" || ModelId == "wesbprincipalbuyers" || ModelId == "wesbprincipalSellers")
                {
                    targetCtl = $("button:visible", '.viewSection');
                }
                else if (ModelId == "customerSearch")
                {
                    targetCtl = $('#skipToCustInfo');
                }
                else
                {
                    targetCtl = $('input:visible,textarea:visible', '.viewSection');
                }

                if (targetCtl.length > 0)
                {
                    targetCtl.eq(0).focus();
                }
                else
                {
                    $('button:visible', ".order-page-footer").eq(0).focus();
                }
            }, 600);
        }


        function SetSideNavigationHeight()
        {
            var vp_h = $(window).height();
            var minBodyHeight = vp_h - $('#header').height() - $('#footer').height();
            var contentBodyHeight = $('#Id-page-host').height();
            if (contentBodyHeight > minBodyHeight)
                $('#SideMenu').attr('style', 'max-height:' + contentBodyHeight + 'px !important');
            else
                $('#SideMenu').attr('style', 'max-height:' + minBodyHeight + 'px !important');
        }

    });

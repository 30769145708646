define('viewmodels/userModule/inHereUpgrade',['plugins/router', 'durandal/app', 'services/security', 'knockout', 'jquery', 'plugins/http', 'global/session', 'config', 'alertNotification', 'migrationModel', 'jquerycapslock', 'services/logger'],
    function (router, app, security, ko, $, https, session, config, alerter, migrationModel, jquerycapslock, logger) {
        var migrationModel = new migrationModel.migrate();
        var loginError = ko.observable('');
        var isApproved = ko.observable('');
        var userInfo;
        var userSecurityInfo;
        var isNewLLAEntry;
        var isLoggedIn = ko.observable(false);


        var vm = {
            activate: activate,
            deactivate: resetControls,
            goBack: goBack,
            title: 'login',
            session: session,
            setFocus: ko.observable(true),
            migrationModel: migrationModel,
            loaded: false,
            login, login,
            attached: AfterLoad,
            handleKeyUp: handleKeyUp,
            loginError: loginError,
            onBlurEmail: onBlurEmail,
            isApproved: isApproved,
            session: session,
            showError: showError,
            cancelUpgrade: cancelUpgrade,
            compositionComplete: function () {
                $('#mdUpgradeInfoPopup').modal('show');
            }
        };

        vm.validationErrors = ko.validation.group([vm.migrationModel.userName, vm.migrationModel.password]);

        return vm;

        function onBlurEmail() {
            loginError('');
        }

        function handleKeyUp(d, e) {
            if (e.keyCode == 13) {
                login();
            }
        }

        function activate() {
            //reset();
        }

        function goBack(complete) {

            router.navigateBack();
        }

        function cancelUpgrade(complete) {

            window.location = window.location.origin;
        }

        function resetControls() {
            $('#LoginUserName').val('');
            $('#LoginPassword').val('');
            vm.migrationModel.userName("");
            vm.migrationModel.password("");
        }

        function login() {

            loginError('');
            session.isBusy(true);

            $("#mdProcessing").modal({ backdrop: 'static', keyboard: false });
            $("#mdProcessing").modal('show');

            if ($('#LoginUserName').val().length > 0) {
                vm.migrationModel.userName($('#LoginUserName').val());
                vm.migrationModel.validateEmail(true);
            }
            else {
                session.isBusy(false);
                $("#mdProcessing").modal('hide');
                return;
            }

            
            if (vm.validationErrors().length > 0) {

                vm.validationErrors.showAllMessages();
                return;
            }
                      

            
            session.isAjaxPost(true);
            //session.clearAccessToken();
            //session.clearACSFToken();
            //session.clearRecentSearchesFromLocalStorage();
            loginError('');

            var url = config.sendOktaOneTimeCodeEmailUrl;

            var request = {
                "email": migrationModel.userName()
            };

            var EmailAddress = migrationModel.userName();
            $("#LoginUserName").attr("readonly", true);

            https.post(url, EmailAddress)
                .done(function (data) {
                    if (data.HasErrors == false) {
                        /*if(data)*/
                        //messages = data.Messages;
                        //messages.forEach(processMessage);

                        //Check messages for returnUrl
                        //if(data.m)
                        //session.OKtaReturnUrl(returnUrl)
                        session.inHereUpgradeUserEmail(EmailAddress);
                        localStorage["inHereUpgradeUserEmail"] = EmailAddress;
                        session.oneTimeCodeUid(data.OneTimeCodeUid);
                        localStorage["oneTimeCodeUid"] = data.OneTimeCodeUid;
                        session.isBusy(false);
                        $("#mdProcessing").modal('hide');

                        session.oktaUpgradeStarted(true);
                        
                        router.navigate('inHereVerifyCode', { replace: true, trigger: true });
                    }
                })
                .fail(function (data) {
                    session.isBusy(false);
                    $("#mdProcessing").modal('hide');
                    alerter.error("There was an error sending the validation code email. Please try again.", { timeOut: 0 });
                })
                .always(function () {
                    session.isBusy(false);
                });
        }
        
        function AfterLoad() {

        }

        function showError(err) {
            const errorEl = document.createElement('div');
            errorEl.textContent = 'Error! ' + err.error_description;
            document.body.insertBefore(
                errorEl,
                document.body.firstChild
            );
        }
    });

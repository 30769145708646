/// <reference path="models/feedback/feedbackModel.js" />
/// <reference path="models/feedback/feedbackModel.js" />
/// <reference path="models/feedback/feedbackModel.js" />
requirejs.config({
    include: [
        //'text!customWidgets/alertsSection/title.html', //custom
        //'text!customWidgets/alertsSection/body.html', //custom

        //'text!customWidgets/exclusions/body.html', //custom
        //'text!customWidgets/exclusions/title.html', //custom

        //'text!customWidgets/submit/body.html', //custom
        //'text!customWidgets/submit/title.html', //custom

    ],
    paths: {
        'login': '../App/viewmodels/userModule/login',
        'text': '../Scripts/text',
        'durandal': '../Scripts/durandal',
        'shell': '../App/viewmodels/shell',
        'plugins': '../Scripts/durandal/plugins',
        'transitions': '../Scripts/durandal/transitions',
        'knockout': '../Scripts/knockout-3.5.1',
        'knockout.validation': '../Scripts/knockout.validation',
        'knockout.mapping': '../Scripts/knockout.mapping',
        'jquery': '../Scripts/jquery-3.7.1',
        'jqueryui': '../Scripts/jquery-ui-1.13.2',
        'jquery.utilities': '../Scripts/jquery.utilities',
        'moment': '../Scripts/moment',
        'printThis': '../Scripts/printThis',
        'bootstrap': '../dist/bootstrap.bundle',
        'bootstrapDatepicker': '../Scripts/bootstrap-datetimepicker',
        'bootstrapSlider': '../Scripts/bootstrap-slider.min',
        'toastr': '../Scripts/toastr',
        'metisMenu': '../Scripts/metisMenu',
        'jquery.slimscroll': '../Scripts/jquery.slimscroll',
        'inspinia': '../Scripts/Inspinia/inspinia',
        'iCheck': '../Scripts/Inspinia/iCheck/icheck.min',
        'steps': '../Scripts/jquery.steps',
        'validate': '../Scripts/jquery.validate',
        'datatable': '../Scripts/datatables.min',
        '_BrandLogo': '../App/viewmodels/_BrandLogo',
        'header': '../App/viewmodels/_Header',
        'maskPlugin': '../Scripts/jquery.maskedinput.min',
        'advanceAddressSearch': '../App/viewmodels/propertySearchModule/advanceAddressSearch',
        'advanceDocumentSearch': '../App/viewmodels/documentSearchModule/advanceDocumentSearch',
        'apnSearch': '../App/viewmodels/propertySearchModule/apnSearch',
        'ownerSearch': '../App/viewmodels/propertySearchModule/ownerSearch',
        'streetSearch': '../App/viewmodels/propertySearchModule/streetIndexLookUp',
        'propertySearchCommon': '../App/viewmodels/propertySearchModule/propertySearchCommon',
        'config': '../App/helper/config',
        'suspendNotification': '../App/helper/suspendNotification',
        'propertySearchModel': '../App/models/propertySearch/propertySearch',
        'documentSearchModel': '../App/models/documentSearch/documentSearch',
        'commonOrderModel': '../App/models/order/orderProcess',
        'commonOrderFieldsModel': '../App/models/order/commonOrderFields',
        'orderInformationModel': '../App/models/order/orderInformation',
        'orderReuesterModel': '../App/models/order/orderReuester',
        'principalBorrowersModel': '../App/models/order/principalBorrowers',
        'principalsBuyersModel': '../App/models/order/principalsBuyers',
        'principalSellerModel': '../App/models/order/principalSeller',
        'subjectPropertyModel': '../App/models/order/subjectProperty',
        'lender1Model': '../App/models/order/lender1',
        'orderCommon': '../App/viewmodels/orderModule/orderCommon',
        'loginModel': '../App/models/user/loginModel',
        'migrationModel': '../App/models/user/migrationModel',
        'registerStep1': '../App/viewmodels/userModule/registerStep1',
        'register': '../App/viewmodels/userModule/register',
        'registerPersonalInfoModel': '../App/models/user/registerPersonalInfoModel',
        'registerAccountInfoModel': '../App/models/user/registerAccountInfoModel',
        'alertNotification': '../App/helper/alertNotification',
        'deviceDetect': '../App/helper/deviceDetect',
        'Q': '../Scripts/q',
        'jquerycapslock': '../Scripts/jquery.capslockstate',
        'koactivity': '..../Scripts/knockout.activity',
        'kocommand': '../Scripts/knockout.command',
        'dirtyFlag': '../Scripts/knockout.dirtyFlag',

        'orderForPropertyAddressModel': '../App/models/order/orderForPropertyAddress',
        'customerInformationModel': '../App/models/order/customerInformation',
        'offLineTitleInformationModels': '../App/models/order/offLineTitleInformation',
        'offlineFarmOrderModels': '../App/models/order/offlineFarmOrder',
        'offlinePropertyProfileModels': '../App/models/order/offlinePropertyProfile',
        'commonDialogModel': '../App/models/commonDialog',
        'utlities': '../App/helper/utlities',
        'purchaseableReportShopItem': '../App/models/propertyDetails/purchaseableReportShopItem',
        'mobileHomeEscrowModel': '../App/models/order/mobileHomeEscrow',
        'refiLenderEscrowModel': '../App/models/order/refiLenderEscrow',
        'bulkSaleOrderModel': '../App/models/order/bulkSaleOrder',
        'residentialEscrow': '../App/models/order/residentialEscrow',
        'chicagoTitleOrderModels': '../App/models/order/chicagoTitleOrder',
        'koPaging': '../Scripts/knockoutpaging',
        'pirtModel': '../App/models/order/pirtOrder',
        'calculatorsModel': '../App/models/calculators/calculators',
        'favoritesModels': '../App/models/favorites/favoritesModels',
        'legalGlossaryModel': '../App/models/legalGlossary/legalGlossary',
        'coloradoRefiModel': '../App/models/order/coloradoRefiLenderEscrow',
        'propertyDetailResponse': '../App/models/propertyDetails/propertyDetailResponse',
        'propertyDetailService': '../App/services/propertyDetailService',
        'addressSearchService': '../App/services/AddressSearchService',
        'viewOrderService': '../App/services/viewOrderService',
        'recentRequests': '../App/viewmodels/propertySearchModule/recentRequests',
        'recentRequestsModel': '../App/models/propertySearch/recentRequestsModel',
        'customSettingsModel': '../App/models/propertySearch/customCompSettings',
        'customCompSettingsVM': '../App/viewmodels/propertySearchModule/customCompSettings',
        'coloradoTitleModel': '../App/models/order/coloradoTitle',
        'propertyProfileModel': '../App/models/propertyDetails/propertyProfile',
        'propertyProfileService': '../App/services/propertyProfileService',
        'nameValue': '../App/models/common/nameValue',
        'nameList': '../App/models/common/nameList',
        'accounting': '../Scripts/accounting',
        'userInformation': '../App/viewmodels/userProfileModule/userInformation',
        'security': '../App/viewmodels/userProfileModule/security',
        'userImages': '../App/viewmodels/userProfileModule/userImages',
        'freqContacts': '../App/viewmodels/userProfileModule/freqContacts',
        'manageContacts': '../App/viewmodels/userProfileModule/manageContacts',
        'teamSettings': '../App/viewmodels/userProfileModule/teamSettings',
        'userInfoModel': '../App/models/userProfile/userInfoModel',
        'busCardLinksModel': '../App/models/userProfile/busCardLinksModel',
        'busCardLinks': '../App/viewmodels/userProfile/busCardLinks',
        'managerInfoModel': '../App/models/userProfile/managerInfoModel',
        'coOEOrderModel': '../App/models/order/coOEOrder',
        'securityModel': '../App/models/userProfile/securityModel',
        'contactsModel': '../App/models/userProfile/contactsModel',
        'teamModel': '../App/models/userProfile/teamSettingsModel',
        'fileUpload': '../App/models/userProfile/FileUpload',
        'viewOfflineModel': '../App/models/order/viewOfflineOrders',
        'viewOnlineModel': '../App/models/order/viewOnlineOrders',
        'viewOrdersModel': '../App/models/order/viewOrders',
        'productOrderModel': '../App/models/productServices/productOrdersSearch',
        'paymentProcessModel': '../App/models/paymentProcess/paymentProcess',
        'officeLocator': '../App/viewmodels/officeLocatorModule/officeLocator',
        'officeLocatorModel': '../App/models/officeLocator/officeLocatorModel',
        'coloradoOECartModel': '../App/models/order/coOEOrderCart',
        'modelMetaData': '../App/models/common/modelMetaData',
        'hoaModel': '../App/models/hoaSearch/hoaSearch',
        'waterSewerModel': '../App/models/waterSewer/waterSewer',
        'llaModel': '../App/models/llaModule/llaModel',
        'salesExecutiveModel': '../App/models/user/salesExecutiveModel',
        'operationInfoModel': '../App/models/operationInfo/operationInfo',
        'successStoryModel': '../App/models/successStories/successStories',
        'changePasswordModel': '../App/models/user/changePasswordModel',
        'getDocumentsModel': '../App/models/customPage/getDocuments',
        'customPageModel': '../App/models/customPage/displayCustomPage',
        'feedbackModel': '../App/models/feedback/feedbackModel',
        'reportAllUSA': '../Scripts/reportAllUSA',  
        'vipServicesModel': '../App/models/vipServices/vipServices',
        'passwordMeter': '../Scripts/password',
        'userOperationModel': '../App/models/userProfile/userOperationsModel',
        'radiusSearch': '../App/viewmodels/propertySearchModule/radiusSearch',
        // 'radiusSearch': '../App/viewmodels/propertySearchModule/radiusSearch1',
        'viewOrder': '../App/models/order/viewOrder',
        'featureUpdate': '../App/models/featureUpdate/featureUpdateModel',
        'businessCardModel': '../App/models/businessCard/businessCard',
        'featureUpdates': '../App/viewmodels/featureUpdatesModule/featureUpdates',
        'featureUpdatesAdmin': '../App/viewmodels/administration/featureUpdatesAdmin',
        'userRoleModel': '../App/models/user/userRoleModel',
        'urlparams': '../Scripts/urlparams.min',
        'addressSearchVM': '../App/viewmodels/propertySearchModule/bingAddressSearch',
        'valuecheckcallback': '../App/viewmodels/payment/valuecheckcallback',
        'corecallback': '../App/viewmodels/payment/corecallback',
        'ftniForm': '../App/models/payment/ftniForm',
        'paymentPurchases': '../App/models/payment/viewPayment',
        'tcpaModel': '../App/models/userProfile/tcpaConsentModel',
        'siteAnnouncementModel': '../App/models/user/siteAnnouncementsModel',
        'viewPendingApprovals': '../App/viewmodels/administration/viewPendingApprovals',
        'pendingApprovalModel': '../App/models/administration/pendingApprovalModel',
        'pendingApprovalService':'../App/services/pendingApprovalService',
        'alertNotificationAtBottomRight':'../App/helper/alertNotificationAtBottomRight',
        //'jQueryMigrate': '../Scripts/jquery-migrate-3.3.0'

    },
    urlArgs: "version=" + "?v85.1",
    shim: {

        'bootstrapSlider': {
            deps: ['jquery']
        },
        'jquery.utilities': {
            deps: ['jquery']
        },
        'steps': {
            deps: ['jquery']
        },
        'iCheck': {
            deps: ['jquery']
        },
        'bootstrap': {
            deps: ['jquery'],
            exports: 'jQuery'
        },
        'bootstrapDatepicker':
            {
                deps: ['jquery', 'moment', 'bootstrap']
            },
        'knockout.validation': {
            deps: ['knockout']
        },
        'knockout.mapping': {
            deps: ['knockout']
        },
        'metisMenu': {
            deps: ['jquery']
        },
        'jquery.slimscroll': {
            deps: ['jquery']
        },
        'inspinia': {
            deps: ['metisMenu', 'jquery.slimscroll']
        },
        'Q': {
            deps: ['jquery']

        },
        'jquerycapslock': {
            deps: ['jquery']

        },
        'koactivity': {
            deps: ['jquery', 'knockout']

        },
        'kocommand': {
            deps: ['jquery', 'knockout']

        },
        'dirtyFlag': {
            deps: ['jquery', 'knockout']

        },
        'accounting': {
            deps: ['jquery', 'knockout']

        },
        'passwordMeter': {
            deps: ['jquery']
        },
        //'jQueryMigrate': {
        //    deps: ['jquery']
        //},
        'config': { exports: 'config' },
        'reportAllUSA': { exports: 'reportAllUSA' }

    },
    waitSeconds: 0
});

define('main',['plugins/router', 'durandal/system', 'durandal/app', 'durandal/viewLocator', 'durandal/composition', 'global/session', 'knockout', 'knockout.validation', 'jquery', 'jquery.utilities', 'jqueryui', 'bootstrap', 'metisMenu', 'steps', 'validate', 'iCheck', 'inspinia',  'Q', 'moment', 'config', 'shell', 'alertNotification'],
    function (router, system, app, viewLocator, composition, session, ko, koValidation, jquery, jqutilities, jqueryui, bootstrap, metisMenu, steps, validate, iCheck, inspinia,  Q, moment, config, shell, alertNotification) {
        //>>excludeStart("build", true);
        system.debug(false);
        //>>excludeEnd("build");

        // app.title = 'Chicago Premier Title Services ';

        app.configurePlugins({
            router: true,
            dialog: true,
            widget: true
        });

        composition.addBindingHandler('hasFocus');

        configureKnockout();
        checkSession();
        startPoll();

        system.defer = function (action) {
            var deferred = Q.defer();
            action.call(deferred, deferred);
            var promise = deferred.promise;
            deferred.promise = function () {
                return promise;
            };
            return deferred;
        };

        $(document).ready(function () {
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = function () {
                window.history.pushState(null, "", window.location.href);
            };
        });

        app.start().then(function () {
            //Replace 'viewmodels' in the moduleId with 'views' to locate the view.
            //Look for partial views in a 'views' folder in the root.
            viewLocator.useConvention();
            //Show the app by setting the root view model for our application with a transition.
            app.setRoot('viewmodels/shell', 'entrance');
            $(document).ajaxStart(function (event) {
                //console.log('ajax started');
                // loader.on("fixed", "-5px", "#FFF", "prepend");
                //  if (router.activeInstruction() && router.activeInstruction().fragment!='' && router.activeInstruction().fragment!='login')
                if (session.isLoggedIn()) {
                    $("#mdLoading").modal({ backdrop: 'static', keyboard: false });
                    session.isAjaxPost(true);
                }
                else {
                    //$("#mdLoading").modal('hide');
                    session.isAjaxPost(false);
                }
            }).ajaxSend(function (event, xhr, option) {
                //console.log('ajax send started');
                var ssp = false;
                if (window.suspendBusy) {
                    ssp = window.suspendBusy();
                }
                if (ssp === true) {///option !== undefined && option.url.indexOf("api/Property/GetUsageDetails") > -1) {
                    $("#mdLoading").modal('hide');
                } else if (session.isLoggedIn() && !session.isLoadingBlocked()) {
                    /*$("#mdLoading").modal({ backdrop: 'static', keyboard: false });*/
                    $("#mdLoading").modal('show');
                    session.isAjaxPost(true);
                    
                }
                else {
                    $("#mdLoading").modal('hide');
                    session.isAjaxPost(false);
                }
            }).ajaxComplete(function (xhr, status) {
                if (session.isLoggedIn() && status.status == 401) {
                    session.hasLoggedOut(true);
                    session.isAjaxPost(false);
                    session.isLoggedIn(false);
                    shell.logout();
                    //$("#mdLoading").modal('hide');
                    //$("div.modal-backdrop.fade.out").removeClass("out");
                    //$("div.modal-backdrop.fade").addClass("in");
                }
                else {
                    if (session.isLoggedIn()) {
                        session.lastAjaxCall(new Date());
                        session.isLoadingBlocked(false);
                    }
                    else {
                        //$("#mdLoading").modal('hide');
                        session.isAjaxPost(false);
                    }
                }
                //console.log('ajax complete');
                //session.isAjaxPost(false);
            })
                .ajaxError(function (jqXHR, textStatus, errorThrown) {
                    //$("#mdLoading").modal('hide');
                    //$("div.modal-backdrop.fade.in").removeClass("in");
                    //$("div.modal-backdrop.fade").addClass("out");
                    session.isAjaxPost(false);
                    session.isBusy(false);
                    session.isLoadingBlocked(false);
                    //console.log('ajax error');
                })
                .ajaxStop(function () {
                    $("#mdLoading").modal('hide');
                    session.isAjaxPost(false);
                    //console.log('ajax stop');
                });
        });

        function checkSession() {
            if (session.isLoggedIn() && session.lastAjaxCall() != undefined) {
                var currentTime = new Date();
                var time = moment(currentTime).diff(moment(session.lastAjaxCall()));
                // console.log('last call time: ' + time);
                // console.log('timeout: ' + session.sessionTimeoutVal() * 60 * 1000);
                if (time > (session.sessionTimeoutVal() * 60 * 1000)) {
                    sessionLogout();
                }
                else {
                    if (localStorage["logout"] != undefined && localStorage["logout"] != null && localStorage["logout"] == "true") {
                        //window.location.href = "#login";
                        session.hasLoggedOut(true);
                        sessionLogout();
                    }
                    else
                        session.hasLoggedOut(false);
                }
            }
        }

        function sessionLogout() {
            console.log('inside SessionLogout!');
            session.icUid('');
            session.icPass('');
            session.hasLoggedOut(true);
            shell.logout();
            session.isLoggedIn(false);
            $("#mdLoading").modal('hide');
            $('.modal').modal('hide');
            $('.modalBlockout').hide();
            $('.modalHost').hide();

            session.isAjaxPost(false);
            $("div.modal-backdrop.fade.out").removeClass("out");
            $("div.modal-backdrop.fade").addClass("in");
            $('.modal').modal('hide');
        }

        function startPoll() {
            window.setInterval(checkSession, (1000 * 60));
        }

        function configureKnockout() {
            ko.validation.init({
                insertMessages: true,
                decorateElement: true,
                errorElementClass: 'has-error',
                errorMessageClass: 'help-block',
                grouping: { deep: true, observable: true }
            });

            if (!ko.utils.cloneNodes) {
                ko.utils.cloneNodes = function (nodesArray, shouldCleanNodes) {
                    for (var i = 0, j = nodesArray.length, newNodesArray = []; i < j; i++) {
                        var clonedNode = nodesArray[i].cloneNode(true);
                        newNodesArray.push(shouldCleanNodes ? ko.cleanNode(clonedNode) : clonedNode);
                    }
                    return newNodesArray;
                };
            }

            ko.bindingHandlers.ifIsInRole = {
                init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                    ko.utils.domData.set(element, '__ko_withIfBindingData', {});
                    return { 'controlsDescendantBindings': true };
                },
                update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                    var withIfData = ko.utils.domData.get(element, '__ko_withIfBindingData'),
                        dataValue = ko.utils.unwrapObservable(valueAccessor()),
                        shouldDisplay = session.userIsInRole(dataValue),
                        isFirstRender = !withIfData.savedNodes,
                        needsRefresh = isFirstRender || (shouldDisplay !== withIfData.didDisplayOnLastUpdate),
                        makeContextCallback = false;

                    if (needsRefresh) {
                        if (isFirstRender) {
                            withIfData.savedNodes = ko.utils.cloneNodes(ko.virtualElements.childNodes(element), true /* shouldCleanNodes */);
                        }

                        if (shouldDisplay) {
                            if (!isFirstRender) {
                                ko.virtualElements.setDomNodeChildren(element, ko.utils.cloneNodes(withIfData.savedNodes));
                            }
                            ko.applyBindingsToDescendants(makeContextCallback ? makeContextCallback(bindingContext, dataValue) : bindingContext, element);
                        } else {
                            ko.virtualElements.emptyNode(element);
                        }

                        withIfData.didDisplayOnLastUpdate = shouldDisplay;
                    }
                }
            };

            function validator_N_remover(Val) {
                var newelement;
                var Replacer;
                var element = Val;
                if (element != null || element != undefined) {
                    var N = element.charCodeAt(element.length - 1);
                    if ((parseInt(N) >= 48 && parseInt(N) <= 57) || parseInt(N) == 46) {
                        return element;
                    }
                    else {
                        Replacer = element.charAt(element.length - 1);
                        newelement = element.replace(Replacer, '');
                        newelement = element.replace(/[^0-9.]/g, '');
                        return newelement;
                    }
                }
            }
            ko.bindingHandlers.amount = {
                init: function (element, valueAccessor) {
                    $(element).on("keyup", function (event) {
                        var text = $(this).val();
                        if (text.indexOf('.') == 0) {
                            event.preventDefault();
                            text = "";
                        }

                        text = text.replace(/^0+(?=\d)/, '').replace(/^([^.]*\.)(.*)$/, function (a, b, c) {
                            return b + c.replace(/\./g, '');
                        });

                        if ((text.indexOf('.') != -1) && (text.substring(text.indexOf('.')).length > 2) && (event.which != 0 && event.which != 8) && ($(this)[0].selectionStart >= text.length - 2)) {
                            if (event.keyCode == 39 || event.keyCode == 37) {
                            }
                            else {
                                event.preventDefault();
                                text = text.slice(0, text.indexOf('.') + 3);
                            }

                        }
                        // Allow: backspace, delete, tab, escape, and enter
                        if (event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 27 || event.keyCode == 13 ||
                            // Allow: Ctrl+A
                            (event.keyCode == 65 && event.ctrlKey === true) ||
                            // Allow: .
                            (((event.keyCode == 190 && !event.shiftKey) && (event.keyCode == 190 && this.value.indexOf(".") == -1)) || (event.keyCode == 110 && this.value.indexOf(".") == -1)) ||
                            // Allow: home, end, left, right
                            (event.keyCode >= 35 && event.keyCode <= 39)) {
                            // let it happen, don't do anything
                            return;
                        }
                        else {
                            // Ensure that it is a number and stop the keypress
                            if (event.shiftKey || (event.keyCode == 190 && this.value.indexOf(".") != -1) || (event.keyCode == 110 && this.value.indexOf(".") != -1) || (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
                                if (event.keyCode == 39 || event.keyCode == 37) {
                                }
                                else {
                                    event.preventDefault();
                                }
                            }
                        }
                        if (text != null || text != undefined) {
                            $(this).val(text.replace(/[^0-9.]/g, '')).trigger("change");
                            return;
                        }
                    });
                }
            };

            ko.bindingHandlers.emailField = {
                init: function (element, valueAccessor) {
                    $(element).on("keydown", function (event) {
                        // Allow: backspace, delete, tab, escape, and enter
                        if ((event.shiftKey && event.keyCode == 188) || (event.shiftKey && event.keyCode == 190)) {
                            alertNotification.error('"<" or ">" characters are not allowed.');
                            event.preventDefault();
                        }
                    });
                }
            };

            ko.bindingHandlers.doNotAcceptAngleBrackets = {
                init: function (element, valueAccessor) {
                    $(element).on("keydown", function (event) {
                        // Allow: backspace, delete, tab, escape, and enter
                        if ((event.shiftKey && event.keyCode == 188) || (event.shiftKey && event.keyCode == 190)) {
                            alertNotification.error('"<" or ">" characters are not allowed.');
                            event.preventDefault();
                        }
                    });
                    ko.utils.registerEventHandler(element, "blur", function () {
                        var value = $(element).val();
                        if (value != undefined && value != null && value != "")
                            $(element).val(value.replace(/[<>]/g, '')).trigger("change");
                    });
                }
            };


            ko.bindingHandlers.amountWithFormating = {
                init: function (element, valueAccessor) {
                    $(element).on("keyup", function (event) {
                        var text = $(this).val();
                        if (text.indexOf('.') == 0) {
                            event.preventDefault();
                            text = "";
                        }

                        text = text.replace(/^0+(?=\d)/, '').replace(/^([^.]*\.)(.*)$/, function (a, b, c) {
                            return b + c.replace(/\./g, '');
                        });

                        if ((text.indexOf('.') != -1) && (text.substring(text.indexOf('.')).length > 2) && (event.which != 0 && event.which != 8) && ($(this)[0].selectionStart >= text.length - 2)) {
                            if (event.keyCode == 39 || event.keyCode == 37) {
                            }
                            else {
                                event.preventDefault();
                                text = text.slice(0, text.indexOf('.') + 3);
                            }

                        }
                        // Allow: backspace, delete, tab, escape, and enter
                        if (event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 27 || event.keyCode == 13 ||
                            // Allow: Ctrl+A
                            (event.keyCode == 65 && event.ctrlKey === true) ||
                            // Allow: .
                            (((event.keyCode == 190 && !event.shiftKey) && (event.keyCode == 190 &&
                                this.value.indexOf(".") == -1)) || (event.keyCode == 110 && this.value.indexOf(".") == -1)) ||
                            // Allow: home, end, left, right
                            (event.keyCode >= 35 && event.keyCode <= 39)) {
                            // let it happen, don't do anything
                            if (!(event.keyCode >= 35 && event.keyCode <= 39)) {
                                if (text != null || text != undefined) {
                                    text = formatInput(text);
                                    $(this).val(text.replace(/[^0-9.,]/g, '')).trigger("change");
                                }
                            }
                            return;
                        }
                        else {
                            // Ensure that it is a number and stop the keypress
                            if (event.shiftKey || (event.keyCode == 190 && this.value.indexOf(".") != -1) ||
                                (event.keyCode == 110 && this.value.indexOf(".") != -1) ||
                                (event.keyCode < 48 || event.keyCode > 57) &&
                                (event.keyCode < 96 || event.keyCode > 105)) {
                                if (event.keyCode == 39 || event.keyCode == 37) {
                                }
                                else {
                                    event.preventDefault();
                                }
                            }
                        }

                        if (text != null || text != undefined || text != "") {
                            text = formatInput(text);
                            $(this).val(text.replace(/[^0-9.,]/g, '')).trigger("change");
                            return;
                        }
                    });
                },
                update: function (element, valueAccessor, allBindingsAccessor) {
                    var value = ko.utils.unwrapObservable(valueAccessor());
                    $(element).val(value);
                }
            };

            ko.bindingHandlers.autoComplete = {
                // Only using init event because the Jquery.UI.AutoComplete widget will take care of the update callbacks
                init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
                    $(element).on("keyup", function (event) {
                        // { selected: mySelectedOptionObservable, options: myArrayOfLabelValuePairs }
                        var settings = valueAccessor();

                        var selectedOption = settings.selected;
                        var options = settings.options;

                        $(element).autocomplete({
                            source: function (request, response) {
                                var matcher = new RegExp("^" + $.ui.autocomplete.escapeRegex(request.term), "i");
                                response($.grep(options, function (item) {
                                    return matcher.test(item.label);
                                }));
                            },
                            minLength: 3,
                            select: function (event, ui) {
                                event.preventDefault();
                                $(element).val(ui.item.label).trigger("change");
                            },
                            focus: function (event, ui) {
                                event.preventDefault();
                                $(element).val(ui.item.label).trigger("change");
                            }
                        });
                    });
                }
            };

            ko.bindingHandlers.hidden = {
                update: function (element, valueAccessor) {
                    var value = ko.utils.unwrapObservable(valueAccessor());
                    ko.bindingHandlers.visible.update(element, function () { return !value; });
                }
            };

            function formatInput(value) {
                value += '';
                value = value.replace(/,/g, '');
                var rgx = /(\d+)(\d{3})/;
                while (rgx.test(value)) {
                    value = value.replace(rgx, '$1' + ',' + '$2');
                }
                return value;
            }
        }
    });

define('register',['plugins/router', 'durandal/app', 'shell', 'services/security', 'global/session', 'services/logger', 'jquery', 'knockout', 'knockout.validation', 'maskPlugin', 'config', 'plugins/http', 'registerAccountInfoModel', 'registerPersonalInfoModel', 'alertNotification', 'Q', 'jquerycapslock', 'passwordMeter', 'securityModel'],
    function (router, app, shell, security, session, logger, $, ko, validation, maskPlugin, config, https, registerAccountInfoModel, registerPersonalInfoModel, alert, Q, jquerycapslock, passwordMeter, securityModel)
    {

        var currentStep = ko.observable('userModule/_registerStep1.html');
        var newStep = ko.observable('');
        var pageHeader = ko.observable('New User Registration');
        var token = ko.observable('');
        var iniLLAAccepted = ko.observable(false);
        var showChangePassword = ko.observable(false);
        var currentUserId = ko.observable();
        var showPasswordTipsCss = ko.observable('');
        var isRepByCounty = ko.observable(true);
        var cssBtnCounty = ko.observable('');
        var cssBtnState = ko.observable('');
        var spnSelectedRepText = ko.observable('');
        var hasSaleRepSentRegistrationLink = ko.observable(false);
        var uniqueIdOfSaleRepWhoSentRegistrationLink = ko.observable('');
        var message = {
            header: ko.observable(''),
            body: ko.observable(''),
            footer: ko.observable('')
        };

        session.showLogin(true);

        var msgUserExistsAnotherBrand = {
            header: 'Good News!',
            body: '<p>We found you in the system.</p>' +
                '<p>We are going to send you an email to verify the information.</p>' +
                '<p>Please check your email to complete the registration process.</p>',
            footer: 'Thank you!'
        };

        var msgAfterFirstStep = {
            header: 'Good News! You are almost done.',
            body: '<p>Please check your email to complete the registration process.</p>',
            footer: 'Thank you!'
        };

        var msgAlreadyRegistered = {
            header: 'New User Registration',
            body: "Oops! It seems you are already a member. Please contact your Sales Executive.",
            footer: ''
        };

        var msgRegistrationCompleted = {
            header: 'Registration successful',
            body: '<p>Your request has been submitted successfully.<p>' +
                '<p>If you have any questions or concerns, please contact your Sales Executive, <SalesRepName>.</p>' +
                '<p>You will receive an email once you are approved.</p>',
            footer: 'Thank You!'
        };

        var msgPasswordUpdated = {
            header: 'Registration successful',
            body: '<p>Your password has been updated.<p>' +
                '<p>If you have any questions or concerns, please contact your Sales Executive.</p>' +
                '<p><a href="#login">Click here</a> to go to the Login page.</p>',
            footer: 'Thank You!'
        };

        var registerAccountInfo = new registerAccountInfoModel.registerAccountInfo();
        var registerPersonalInfo = new registerPersonalInfoModel.registerPersonalInfo();
        var passwordModel = new securityModel.PasswordModel();
        var response = ko.observableArray();
        var pluginFocus = ko.observable(false);
        var isRegistrationComplete = ko.observable(false);
        var stateList = ko.observableArray();
        var countyList = ko.observableArray();
        // var fipsInfo = ko.observableArray();
        var parentUserList = ko.observableArray();
        var monthList = ko.observableArray();
        var fips = ko.observable('');
        var selectedState = ko.observable('');
        var selectedCounty = ko.observable('');

        var selectedStateName = ko.observable('');
        var selectedMonth = ko.observable('');
        var dateofBirth = ko.observable('');


        var isNotRobotValidated = ko.observable(true);
        var step1Valid = ko.observable(false);

        var repImagePath = ko.observable('');
        var repPhone = ko.observable('');
        var repEmail = ko.observable('');
        var repImage = ko.observable('');
        var captchaResponse = '';

        var currentExecutiveData = ko.observable('');
        var isSalesExecutiveBusy = ko.observable(false);
        var isCountyBusy = ko.observable(false);
        var termsUrl = ko.observable('');
        var agreeLLA = ko.observable(false);
        var countySENotAvailable = ko.observable(false);

        var IsNewUserRegistration = ko.observable(true);

        pluginFocus.subscribe(function (newValue)
        {
            if (newValue == true)
            {
                setPlugins();
            }
        })

        function clear()
        {
            registerAccountInfo.update(new registerAccountInfoModel.registerAccountInfo());
            registerPersonalInfo.update(new registerPersonalInfoModel.registerPersonalInfo());
            if ((vm.AccountvalidationErrors().length > 0) && (vm.PersonalvalidationErrors().length > 0))
            {
                vm.AccountvalidationErrors.showAllMessages(false);
                vm.PersonalvalidationErrors.showAllMessages(false);
                return;
            }

        };

        function resetForm()
        {
            clear();
            $('.register1').show();
            $('.register2').hide();
            session.showLogin(true);
            currentStep('userModule/_registerStep1.html');
            newStep('');
            pageHeader('New User Registration');
            token('');
            iniLLAAccepted(false);
            showChangePassword(false);
            currentUserId(undefined);
            message.header('');
            message.body('');
            message.footer('');
            //activate();
            //window.location.reload(true)
        };

        agreeLLA.subscribe(function (selected)
        {
            registerPersonalInfo.agreeTerms(selected);
        });

        function showLLAAgreement()
        {
            $('#mdLLAAgreementRegistration').modal('show');
        }

        function closeLLADialog()
        {
            $('#mdLLAAgreementRegistration').modal('hide');
        }

        function setupCapsChangeNotifiers()
        {

            $(window).on("capsOff capsUnknown", function (e)
            {
                $(".capsnotifier").hide();
            });

            $("input:password").on("focusout", function (e)
            {
                $(".capsnotifier").hide();
            });

            $("input:password").on("focusin", function (e)
            {
                if ($(window).capslockstate("state") === true)
                    $('.' + this.id).show();
                else
                    $(".capsnotifier").hide();
            });
        }

        var vm = {
            toErrorString: toErrorString,
            setupCapsChangeNotifiers: setupCapsChangeNotifiers,
            messageHeader: message.header,
            messageBody: message.body,
            messageFooter: message.footer,
            iniLLAAccepted: iniLLAAccepted,
            activate: activate,
            goBack: goBack,
            title: 'register',
            newStep: newStep,
            pageHeader: pageHeader,
            session: session,
            setFocus: ko.observable(true),
            registerAccountInfo: registerAccountInfo,
            registerPersonalInfo: registerPersonalInfo,
            passwordModel: passwordModel,
            register: register,
            currentStep: currentStep,
            loaded: false,
            login: login,
            nextStep: nextStep,
            prevStep: prevStep,
            compositionComplete: compositionComplete,
            isRegistrationComplete: isRegistrationComplete,
            changePassword: changePassword,
            submitNewPassword: submitNewPassword,
            showChangePassword: showChangePassword,
            currentUserId: currentUserId,
            ShowLLAAgreement: showLLAAgreement,
            CloseLLADialog: closeLLADialog,
            countySENotAvailable: countySENotAvailable,
            isNotRobotValidated: isNotRobotValidated,
            showPasswordTipsCss: showPasswordTipsCss,
            selectedStateName: selectedStateName,
            isRepByCounty: isRepByCounty,
            cssBtnCounty: cssBtnCounty,
            cssBtnState: cssBtnState,
            spnSelectedRepText: spnSelectedRepText,
            currentStepAttached: function (child, parent, settings)
            {
                console.log('currentStepAttached : called');
                session.validateInput();
                setupPasswordMeter('.');
                setupCapsChangeNotifiers();
                if ($('#changePasswordRG') != null)
                {
                    $('#changePasswordRG').on("click", changePassword);
                }
                //if($('#g-recaptcha') != undefined && $('#g-recaptcha')[0] != undefined)
                //setupCaptcha();
                console.log('currentStep in attached : ' + currentStep());
                console.log('newStep in attached : ' + newStep());
            },

            attached: function ()
            {
                if (!(registerAccountInfo.ValidToken()))
                {
                    //if (currentStep() === "userModule / _registerStep1.html")
                    //    currentStep('userModule/_registerStep1.html');
                    newStep('')
                }
                else
                {
                    currentStep('');
                }


                $(window).on("capsOn", function (e)
                {
                    if ($("#RegisterPassword:focus").length > 0)
                    {
                        $("#capsPasswordWarning").show();
                    }
                });
                $(window).on("capsOff capsUnknown", function (e)
                {
                    $("#capsPasswordWarning").hide();
                });
                $("#RegisterPassword").on("focusout", function (e)
                {
                    $("#capsPasswordWarning").hide();

                });
                $("#RegisterPassword").on("focusin", function (e)
                {
                    if ($(window).capslockstate("state") === true)
                    {
                        $("#capsPasswordWarning").show();


                    }
                });

                $(window).on("capsOn", function (e)
                {
                    if ($("#RegisterConfirmPassword:focus").length > 0)
                    {
                        $("#capsConfirmPasswordWarning").show();

                    }
                });
                $(window).on("capsOff capsUnknown", function (e)
                {
                    $("#capsConfirmPassword").hide();
                });
                $("#RegisterConfirmPassword").on("focusout", function (e)
                {
                    $("#capsConfirmPasswordWarning").hide();

                });
                $("#RegisterConfirmPassword").on("focusin", function (e)
                {
                    if ($(window).capslockstate("state") === true)
                    {
                        $("#capsConfirmPasswordWarning").show();

                    }
                });

                $(window).on("capsOn", function (e)
                {
                    if ($("#SecretAnswer:focus").length > 0)
                    {
                        $("#capsSecretAnswerWarning").show();

                    }
                });
                $(window).on("capsOff capsUnknown", function (e)
                {
                    $("#capsSecretAnswerWarning").hide();
                });
                $("#SecretAnswer").on("focusout", function (e)
                {
                    $("#capsSecretAnswerWarning").hide();

                });
                $("#SecretAnswer").on("focusin", function (e)
                {
                    if ($(window).capslockstate("state") === true)
                    {
                        $("#capsSecretAnswerWarning").show();

                    }
                });
            },

            stateList: stateList,
            countyList: countyList,
            //fipsInfo: fipsInfo,
            fips: fips,
            selectedState: selectedState,
            parentUserList: parentUserList,
            createAccount: createAccount,
            unmaskPhoneNumber: unmaskPhoneNumber,
            monthList: monthList,
            selectedMonth: selectedMonth,
            dateofBirth: dateofBirth,
            pluginFocus: pluginFocus,
            clear: clear,
            enableTooltip: setPlugins,
            getExecutiveData: getExecutiveData,
            currentExecutiveData: currentExecutiveData,
            getAllExecutives: getAllExecutives,
            clearRep: clearRep,
            repPhone: repPhone,
            repEmail: repEmail,
            repImagePath: repImagePath,
            secretAnswer_mouseout: secretAnswer_mouseout,
            handleStep1Enter: handleStep1Enter,
            isSalesExecutiveBusy: isSalesExecutiveBusy,
            isCountyBusy: isCountyBusy,
            icheckBox: icheckBox,
            //isPasswordCapLockOn: isPasswordCapLockOn,
            //isConfirmPasswordCapLockOn: isConfirmPasswordCapLockOn,
            //isSecretAnswerCapLockOn: isSecretAnswerCapLockOn
            TermsUrl: termsUrl,
            agreeLLA: agreeLLA,
            processBrandAccessRequest: processBrandAccessRequest,
            submitBrandAccessRequest: submitBrandAccessRequest,
            resetForm: resetForm,
            IsNewUserRegistration: IsNewUserRegistration,
            getCountyReps: getCountyReps,
            deactivate: function ()
            {
                clear();
                currentStep('');
                newStep('');
            },
            hasSaleRepSentRegistrationLink: hasSaleRepSentRegistrationLink,
            uniqueIdOfSaleRepWhoSentRegistrationLink: uniqueIdOfSaleRepWhoSentRegistrationLink
        };

        function handleStep1Enter(d, e)
        {
            if (e.keyCode == 13)
            {
                nextStep();
            }
        }

        function secretAnswer_mouseout()
        {
            $('#SecretAnswer').blur();
        }


        vm.registerAccountInfo.email.subscribe(function (val)
        {
            // checkExistingBrandUser();
            if (registerAccountInfo != null && registerAccountInfo.ValidToken() == true)
            {
                step1Valid(true);
            }
            else
            {
                if (val.length != 0)
                {
                    try
                    {
                        grecaptcha.reset();
                    }
                    catch (ex)
                    {
                        setupCaptcha();
                    }
                    isNotRobotValidated(true);
                }

                step1Valid(false);
            }
        });

        function checkExistingBrandUser()
        {
            var validEmail = false;

            if (registerAccountInfo.confirmEmail.error() == undefined &&
                registerAccountInfo.email.error() == undefined)
            {
                validEmail = registerAccountInfo.email() != undefined && registerAccountInfo.email() != null && registerAccountInfo.email().length > 0;
            }

            if (validEmail)
            {
                registerAccountInfo.existingBrandUser(false);
                var url = config.userExistUrl;
                Q.when(https.get(url, {
                    username: registerAccountInfo.confirmEmail,
                    format: 'json'
                }, null, null, null, null, true)
                    .then(function (data)
                    {
                        registerAccountInfo.existingBrandUser(data === true);
                        if (registerAccountInfo.existingBrandUser() === true)
                        {
                            alert.warning("We noticed you are already in the system. Please check your email to complete the registration process.");
                        }
                    })
                    .fail(Failed));
            }
        }

        //vm.registerAccountInfo.confirmEmail.subscribe(function (val) {
        //    checkExistingBrandUser();
        //});


        vm.registerPersonalInfo.state.subscribe(function (state)
        {
            (function ($, window)
            {
                var arrowWidth = 90;

                $.fn.resizeselect = function (settings)
                {
                    return this.each(function ()
                    {

                        $(this).change(function ()
                        {
                            var $this = $(this);

                            // create test element
                            var text = $this.find("option:selected").text();
                            var $test = $("<span>").html(text);

                            // add to body, get width, and get out
                            $test.appendTo('body');
                            var width = $test.width();
                            $test.remove();

                            // set select width
                            $this.width(width + arrowWidth);

                            // run on start
                        }).change();

                    });
                };

                // run by default
                $("select.resizeselect").resizeselect();

            })(jQuery, window);
            if (state != undefined && state != '')
            {
                selectedState = state;
                getCountiesListByState(state);
            }


        });

        vm.registerPersonalInfo.county.subscribe(function (county)
        {
            if (county != undefined && county != '')
            {

                return ko.utils.arrayFilter(countyList(),
                    function (item)
                    {
                        if (item.Value == county)
                        {
                            var countyName = item.Key;
                            selectedCounty(countyName);
                            countySENotAvailable(false);
                            //getParentUserList(selectedState, countyName);
                            if (!hasSaleRepSentRegistrationLink())
                                getCountyReps();
                        }
                    });
            }


        });

        function getCountyReps()
        {
            isRepByCounty(true);
            getParentUserList(selectedState, selectedCounty());
            spnSelectedRepText('Sales executives of ' + selectedCounty() + ' county.');
        }

        isRepByCounty.subscribe(function (isRepByCounty)
        {
            if (isRepByCounty)
            {
                cssBtnCounty('btn btn-primary');
                cssBtnState('btn btn-secondary');
            } else
            {
                cssBtnCounty('btn btn-secondary');
                cssBtnState('btn btn-primary');
            }
        });

        vm.registerPersonalInfo.zipCode.subscribe(function (zip)
        {
            if (zip != undefined && zip != '')
            {
                getZipInfo(zip);
            }


        });
        vm.registerPersonalInfo.birthdayMonth.subscribe(function (month)
        {
            selectedMonth = month;

            if (month == "1" || month == "3" || month == "5" || month == "7" || month == "8" || month == "10" || month == "12")
            {
                monthList([]);
                for (var i = 1; i <= 31; i++)
                    monthList.push(i);
            }
            else if (month == "4" || month == "6" || month == "9" || month == "11")
            {
                monthList([]);

                for (var i = 1; i <= 30; i++)
                    monthList.push(i);
            }
            else if (month == "2")
            {
                monthList([]);
                var year = new Date().getFullYear();
                if (((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0))
                {
                    for (var i = 1; i <= 29; i++)
                        monthList.push(i);
                }
                else
                {
                    for (var i = 1; i <= 28; i++)
                        monthList.push(i);
                }

            }
            return monthList;

        });

        registerPersonalInfo.salesExecutive.subscribe(function ()
        {
            session.isBusy(false);
            session.isBusy(true);
            if (registerPersonalInfo.salesExecutive() != undefined)
            {

                $('#salesExecutive').popover('dispose');

                Q.when(https.get(config.getRepInfobyIdUrl, {
                    userId: registerPersonalInfo.salesExecutive(),
                    format: 'json'
                }, null, null, null, null, true)
                    .then(function (userData)
                    {
                        repImagePath(userData.Photo);

                        if (userData.Email.length > 0)
                        {
                            //      var email = '<a href="mailto:' + userData.Email + '" target="_top" style="color:white;">' + userData.Email + '</a>';
                            repEmail(userData.Email);
                        }

                        if (userData.PhoneWork.length == 10)
                        {
                            var phone = userData.PhoneWork.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
                            repPhone(phone);
                        }

                        repImage('');

                        if (userData.Photo.length > 0)
                        {
                            repImage('<img src="Documents/UserPhotos/' + repImagePath() + '" class="img-fluid rounded-circle mr-2 regSalesRepImg" alt="Profile Image">');
                        }

                        //var OrientationPositioning;
                        //window.addEventListener('resize', function () {
                        //    OrientationPositioning = UpdateDeviceWidth();
                        //});

                        //if (UpdateDeviceWidth() < 1025) {
                        //    $('#salesExecutive').popover({
                        //        placement: 'top',
                        //        html: true,
                        //        title: '',
                        //        trigger: 'focus',
                        //        content: '<div class="media">' + repImage() + '<div class="media-body">Thank you for selecting me. If you need help with the Registration process, please contact me : <br/>' + repPhone() + ' or ' + repEmail() + '</div></div>'
                        //    });
                        //}
                        //else {
                        //    $('#salesExecutive').popover({
                        //        placement: 'right',
                        //        html: true,
                        //        title: '',
                        //        trigger: 'focus',
                        //        content: '<div class="media">' + repImage() + '<div class="media-body">Thank you for selecting me. If you need help with the Registration process, please contact me : <br/>' + repPhone() + ' or ' + repEmail() + '</div></div>'
                        //    });
                        //}

                        //$('#salesExecutive').popover('show').off('click'); // To Keep Popover Open

                        //$('#salesExecutive').popover('show');

                        //$('body').on("click", ".popover-header .close", function () {
                        //    $(this).parents(".popover").popover('hide');
                        //    $('#salesExecutive').popover('dispose');
                        //    return false;
                        //});
                        //$('body').on("touchstart", function () {
                        //    $(this).parents(".popover").popover('hide');
                        //    $('#salesExecutive').popover('dispose');
                        //    return false;
                        //});

                        //$(document).on("click", ".popover-header .close", function () {
                        //    $(this).parents(".popover").popover('hide');
                        //    $('#salesExecutive').popover('dispose');
                        //    return false;
                        //});
                        $('body').on("click touchstart", ".popover-header .close", function ()
                        {
                            $(this).parents(".popover").popover('hide');
                            $('#salesExecutive').popover('dispose');
                            return false;
                        });
                        $("select").change(function ()
                        {
                            $(".popover").popover('hide');
                            $('#salesExecutive').popover('dispose');
                        });

                        var repEmailContact = (userData.Email.length > 0) ? '<a href="mailto:' + userData.Email + '" target="_top">' + userData.Email + '</a> or ' : '';
                        registerPersonalInfo.salesExecutiveContact(userData.FirstName + ' ' + userData.LastName + ' : ' + repEmailContact + repPhone());

                        //$('#salesExecutive').popover({
                        //    placement: 'right',
                        //    html: true,
                        //    title: 'User Info <a href="#" class="close" data-dismiss="alert">×</a>',
                        //    content: '<div class="media"><a href="#" class="pull-left"><img src="../images/avatar-tiny.jpg" class="media-object" alt="Sample Image"></a><div class="media-body"><h4 class="media-heading">Jhon Carter</h4><p>Excellent Bootstrap popover! I really love it.</p></div></div>'
                        //});

                        //$('#salesExecutive').tooltip({
                        //    title: "<div><img src='Content/images/Executives/JohnSmith.png'></img>Thank you for selecting me. If you need help with </br> the Registration process, please contact me : " + repEmail() + " or " + repPhone() + " </div>",
                        //    html: true,
                        //    placement: "right"
                        //});


                    })
                    .fail(Failed)
                );

            }
            session.isBusy(false);
        });

        vm.registerPersonalInfo.birthdayDay.subscribe(function (day)
        {
            dateofBirth = selectedMonth + "/" + day + "/" + 1900;
        });

        var validBoolean = function (value)
        {
            return typeof value === 'boolean';
        };


        vm.AccountvalidationErrors = ko.validation.group([vm.registerAccountInfo]);
        vm.PersonalvalidationErrors = ko.validation.group([vm.registerPersonalInfo]);
        vm.ChangePasswordValidationErrors = ko.validation.group([vm.passwordModel]);
        return vm;
        composition.addBindingHandler('myCustomHandler', {
            init: function ()
            {
                console.log('Custom handler Init');
            },
            update: function ()
            {
                console.log('Custom handler Update');
            }
        });


        function compositionComplete()
        {
            console.log('compositionComplete  called');

            session.validateInput();
            setPlugins();

            setupPasswordMeter('#');
            setupPasswordMeter('.');
        }

        function setupCaptcha()
        {
            grecaptcha.render('g-recaptcha', {
                'sitekey': session.recaptchaKey(),
                'callback': reCaptchaCallback,
                'expired-callback': reCaptchaExpired,
                'error-callback': reCaptchaError

            });
        }

        function reCaptchaCallback(response)
        {
            captchaResponse = response;
            if (captchaResponse != undefined && captchaResponse != '')
                isNotRobotValidated(false);
        }

        function reCaptchaExpired()
        {
            isNotRobotValidated(true);
        }

        function reCaptchaError()
        {
            alert.error('Error connecting to server. Please try after some time.');
        }

        function bindingComplete()
        {
            console.log('bindingComplete called');
            setPlugins();
        }
        function icheckBox()
        {
            console.log('icheckBox called');
            $('.icheckbox_square-blue').iCheck({
                checkboxClass: 'icheckbox_square-blue'
            });
        }

        function setupPasswordMeter(prefix)
        {
            if (prefix == null || prefix.length == 0) prefix = '#';
            var ctrl = $(prefix + 'RegisterPassword');
            if (ctrl != null)
            {
                ctrl.password({
                    shortPass: 'The password is too short',
                    badPass: 'Weak; Try combining upper & lower case letters, numbers & special characters',
                    goodPass: 'Medium; Try combining upper & lower case letters, numbers & special characters',
                    strongPass: 'Strong password',
                    containsUsername: 'The password contains the username',
                    enterPass: 'Type your password',
                    showPercent: false,
                    showText: true, // shows the text tips
                    animate: true, // whether or not to animate the progress bar on input blur/focus
                    animateSpeed: 'fast', // the above animation speed
                    username: false, // select the username field (selector or jQuery instance) for better password checks
                    usernamePartialMatch: true, // whether to check for username partials
                    minimumLength: 8 // minimum password length (below this threshold, the score is 0)
                });
            }
        }



        function setPlugins()
        {
            //console.log('setPlugins() called');
            $('.icheckbox_square-blue').iCheck({
                checkboxClass: 'icheckbox_square-blue'
            });
            $('[data-toggle="tooltip"]').tooltip();
            $('#workPhone').mask("(999) 999-9999");
            //$('#cellPhone').mask("(999) 999-9999");

        }

        function UpdateDeviceWidth()
        {
            return window.innerWidth;
        }

        function activate(event, queryString)
        {
            clear();

            currentStep('userModule/_registerStep1.html');
            newStep('');
            pageHeader('New User Registration');
            token('');
            iniLLAAccepted(false);
            currentUserId(undefined);
            message.header('');
            message.body('');
            message.footer('');
            session.showLogin(true);
            step1Valid(false);
            registerAccountInfo.ValidToken(false);
            isNotRobotValidated(true);
            session.isBusy(false);
            isRegistrationComplete(false);
            isSalesExecutiveBusy(false);
            isCountyBusy(false);
            agreeLLA(false);
            console.log('register activate called');
            GetStatesList();
            SetTermsNConditions();
            showChangePassword(false);
            cssBtnCounty('btn btn-primary');
            cssBtnState('btn btn-secondary');

            if (queryString != undefined && queryString != null && queryString != "")
            {
                if (queryString.newUser != undefined && queryString.newUser != null && queryString.newUser != "" && queryString.newUser.toLowerCase() === "true")
                    IsNewUserRegistration(true);
                else if (queryString.newUser != undefined && queryString.newUser != null && queryString.newUser != "" && queryString.newUser.toLowerCase() === "false")
                    IsNewUserRegistration(false);
                else
                    IsNewUserRegistration(undefined);

                if (queryString.token != undefined && queryString.token != null && queryString.token.toString().length > 0)
                {
                    token(queryString.token);
                    if (IsNewUserRegistration())
                    {
                        preProcessNewUserRegistrationToken(queryString.token);
                    }
                    else
                    {
                        preProcessToken(queryString.token);
                    }
                }
            }
            /* 
             * Initialize the capslockstate plugin.
             * Monitoring is happening at the window level.
             */
            $(window).on("capsChanged", function (event)
            {
                if ($(window).capslockstate("state") === true)
                {
                    console.log('capslockstate on');
                    var ctrl = $("input:password:focus");
                    console.log('capslockstate on' + ctrl.length);
                    if (ctrl.length > 0)
                    {
                        $('.' + ctrl[0].id).show();
                    }
                } else
                {
                    $(".capsnotifier").hide();
                }
            });

            $(window).capslockstate();
        }

        function SetTermsNConditions()
        {
            https.get('durandal/GetFooter', null, null, null, null, null, true).done(function (data)
            {
                termsUrl(data.TermsURL);
            });
        }

        function getExecutiveData()
        {
            //console.log('getExecutiveData() called.');
            // console.log( repEmail());




            //title: "<div><img src='Content/images/Executives/JohnSmith.png'></img>Thank you for selecting me. If you need help with </br> the Registration process, please contact me : " + repEmail() + " or " + repPhone() + " </div>",

            //if (registerPersonalInfo.salesExecutive() != undefined) {
            //    console.log(repEmail());
            //    $('#salesExecutive').tooltip({
            //        title: "<div><img src='Content/images/Executives/JohnSmith.png'></img>Thank you for selecting me. If you need help with </br> the Registration process, please contact me : </br> " + repEmail() + "  or  <span data-bind='text:repPhone'></span> </div>",
            //        html: true,
            //        placement: "right"
            //    });
            //}
        }

        function clearRep()
        {
            // $('#salesExecutive').tooltip('destroy');
        }



        function GetStatesList()
        {
            var url = config.stateUrl;
            stateList([]);
            countyList([]);
            Q.when(https.get(url, {
                format: 'json'
            }, null, null, null, null, true)
                .then(function (data)
                {
                    stateList(data);
                })
                .fail(Failed));
        }

        function getCountiesListByState(state)
        {
            var url = config.countyUrl;
            countyList([]);
            Q.when(https.get(url, {
                stateAbbr: state,
                format: 'json'
            }, null, null, null, null, true)
                .then(function (data)
                {
                    countyList(data);
                    if (fips != null)
                    {
                        registerPersonalInfo.county(fips)
                    }
                })
                .fail(Failed)
            );
        }
        function getZipInfo(zip)
        {
            var url = config.getZipInfoUrl;
            Q.when(https.get(url, {
                zipCode: zip, //'90210',
                format: 'json'
            }, null, null, null, null, true)
                .then(function (data)
                {
                    if (data != undefined && data != null && data.HasErrors == false)
                    {
                        if (data.Fips != null)
                        {
                            registerPersonalInfo.state(data.StateAbbr);
                            registerPersonalInfo.city(data.City);
                            getCountiesListByState(data.StateAbbr);
                            registerPersonalInfo.county(data.Fips);
                            registerPersonalInfo.countyName(data.CountyName);
                            selectedStateName(data.State);
                            fips = data.Fips;
                        }
                        else
                        {
                            alert.error("Invalid Zip.");
                            registerPersonalInfo.zipCode('');
                            registerPersonalInfo.state('');
                            registerPersonalInfo.city(undefined);
                            registerPersonalInfo.county('');
                            fips = '';
                        }
                    }
                    else
                        session.zipCodeServiceDown();
                })

                .fail(Failed));
        }
        function getParentUserList(stateAbbr, countyName)
        {
            var url = config.getParentUserListUrl;
            parentUserList([]);
            isSalesExecutiveBusy(true);
            Q.when(https.get(url, {
                stateAbbr: stateAbbr,
                countyName: countyName,//'90210',
                registrationToken: token(),
                newUser: IsNewUserRegistration(),
                format: 'json'
            }, null, null, null, null, true)
                .then(function (data)
                {
                    if (data.length === 0)
                    {
                        countySENotAvailable(true);
                        getAllExecutives();
                        parentUserList(data);
                        //spnSelectedRepText('Sales executives of ' + selectedStateName + ' state.');
                        if (parentUserList().length > 0)
                        {
                            session.isBusy(false);
                        }

                    }
                    else
                    {
                        //     spnSelectedRepText('Sales executives of ' + selectedCounty() + ' county.');
                        parentUserList(data);
                        if (parentUserList().length > 0)
                        {
                            isSalesExecutiveBusy(false);
                        }

                    }

                })
                .fail(Failed));

        }
        function getAllExecutives()
        {
            isRepByCounty(false);
            getParentUserList(0, "");
            spnSelectedRepText('Sales executives of ' + selectedStateName() + ' state.');
        }

        function getSalesRepByUniqueId(uniqueId)
        {
            
            isRepByCounty(false);
            var url = config.getSalesRepByUniqueIdUrl;
            parentUserList([]);
            isSalesExecutiveBusy(true);
            Q.when(https.get(url, {
                uniqueId: uniqueId,
                registrationToken: token(),
                newUser: IsNewUserRegistration(),
                format: 'json'
            }, null, null, null, null, true)
                .then(function (data)
                {
                    console.log(data);
                    if (data.length === 0)
                    {
                        countySENotAvailable(true);
                        getAllExecutives();
                        parentUserList(data);
                        //spnSelectedRepText('Sales executives of ' + selectedStateName + ' state.');
                        if (parentUserList().length > 0)
                            session.isBusy(false);
                    }
                    else
                    {
                        //     spnSelectedRepText('Sales executives of ' + selectedCounty() + ' county.');
                        parentUserList(data);
                        if (parentUserList().length > 0)
                            isSalesExecutiveBusy(false);
                        if (parentUserList().length === 1)
                        {
                            registerPersonalInfo.salesExecutive(uniqueId);
                            hasSaleRepSentRegistrationLink(true);
                        }
                    }
                })
                .fail(Failed));
            spnSelectedRepText('Sales executives of ' + selectedStateName() + ' state.');
        }

        function unmaskPhoneNumber(phoneNumber)
        {
            var phone = phoneNumber();
            if (phone != undefined && phone != '')
            {
                return phone.replace(/[^\d\+]/g, '');
            }
            else
                return phone;
        };


        function preProcessNewUserRegistrationToken(token)
        {
            session.isBusy(true);
            security.verifyNewUserRegistrationTokenAccess(token)
                .done(function (data)
                {

                    if (data != null && data != undefined)
                    {
                        if (data.HasValidationErrors == true)
                        {
                            alert.error(data.Messages[0].Message, pageHeader());
                        }
                        else if (data.HasErrors == true)
                        {
                            alert.error(data.Messages[0].Message, pageHeader());
                        }
                        else if (data.Email != null)
                        {
                            registerAccountInfo.ValidToken(true);
                            registerAccountInfo.email(data.Email);
                            registerAccountInfo.confirmEmail(data.Email);
                            registerPersonalInfo.isPasswordRequired(data.IsPasswordRequired);
                            if (data.IsPasswordRequired)
                            {
                                showPasswordTipsCss('row d-block d-lg-none mt-3 mt-md-0');
                            } else
                            {
                                showPasswordTipsCss('row d-none d-lg-none mt-3 mt-md-0');
                            }
                            if (data.SalesRepUniqueId != null)
                            {
                                hasSaleRepSentRegistrationLink(true);
                                uniqueIdOfSaleRepWhoSentRegistrationLink(data.SalesRepUniqueId);
                                getSalesRepByUniqueId(data.SalesRepUniqueId)
                            }
                            currentStep('');
                            pageHeader('New User Registration');
                            step1Valid(true);
                            newStep('userModule/_registerStep2.html');
                            setPlugins();
                        }
                        else
                        {
                            registerAccountInfo.ValidToken(false);
                            newStep('');
                            session.showLogin(true);
                            currentStep('userModule/_registerStep1.html');
                            alert.error('We are sorry! We could not find you in our system. Please register.', pageHeader());
                            setPlugins();
                        }
                    }
                })
                .fail(function (data)
                {
                    console.log(data);
                    registerAccountInfo.ValidToken(false);
                    newStep('');
                    session.showLogin(true);
                    currentStep('userModule/_registerStep1.html');
                    alert.error('We are sorry! We could not find you in our system. Please register.', pageHeader());

                }).always(function (data)
                {
                    session.isBusy(false);
                });
        }


        function preProcessToken(token)
        {
            if (token != undefined && token != null && token.toString().length > 0)
            {
                session.isBusy(true);
                security.verifyRequestBrandAccess(token).done(function (data)
                {
                    if (data != null && data != undefined && data.UserId != null && data.Email != null && data.Status == "NotIn")
                    {
                        getParentUserList(data.State, data.County);
                        registerAccountInfo.ValidToken(true);
                        registerAccountInfo.email(data.Email);
                        registerAccountInfo.confirmEmail(data.Email);
                        registerAccountInfo.LLAAccepted(data.LLAAccepted);
                        iniLLAAccepted(data.LLAAccepted);
                        currentUserId(data.UserId);
                        currentStep('');
                        newStep('userModule/_registerAddtoBrand.html');
                        pageHeader('New User Registration');
                    }
                    else if (data != null && data.Messages != null && data.Messages.length > 0)
                    {
                        alert.error(data.Messages[0].Message, pageHeader());
                        registerAccountInfo.ValidToken(false);
                        newStep('');
                        session.showLogin(true);
                        currentStep('userModule/_registerStep1.html');
                    }
                }).fail(function (data)
                {
                    console.log(data);
                    alert.error('We are sorry! We could not find you in our system. Please register.', pageHeader());
                    registerAccountInfo.ValidToken(false);
                    newStep('');
                    session.showLogin(true);
                    currentStep('userModule/_registerStep1.html');
                }).always(function (data)
                {
                    session.isBusy(false);
                });
            }
        }


        function changePassword()
        {
            newStep('userModule/_registerAndUpdatePassword.html');
            setPlugins();
        }

        function processBrandAccessRequest()
        {
            if (token() == undefined || token() == null || token().length == 0)
            {
                alert.error('Invalid Request', pageHeader());
                return;
            }
            if (registerPersonalInfo == null | registerPersonalInfo.salesExecutive() == null || parseInt(registerPersonalInfo.salesExecutive()) == false)
            {
                alert.error('Please select sales executive', pageHeader());
                return;
            }
            else if (registerAccountInfo.LLAAccepted() != true)
            {
                alert.error('User needs to agree to the terms to continue.', pageHeader());
                return;
            }

            var request = {
                token: token(),
                ParentUserId: registerPersonalInfo.salesExecutive(),
                LLAAccepted: registerAccountInfo.LLAAccepted()
            };
            submitBrandAccessRequest(request);
        }


        //registerPersonalInfo.salesExecutive
        function submitBrandAccessRequest(request)
        {
            var url = config.postRequestBrandAccessUrl;
            session.isBusy(true);

            https.post(url, request)
                .then(function (data)
                {
                    $('a.close').click();
                    session.isBusy(false);
                    $("body").scrollTop(0);
                    if (data.HasValidationErrors)
                    {
                        alert.error(data.Messages[0].Message, pageHeader());
                    }
                    else if (data.HasErrors == true)
                    {
                        alert.success(data.Messages[0].Message, pageHeader());
                    }
                    else if (data.Completed == true)
                    {
                        //message = {
                        //    header: ko.observable(msgRegistrationCompleted.header),
                        //    body: ko.observable(msgRegistrationCompleted.body.replace('<SalesRepName>', registerPersonalInfo.salesExecutiveContact())),
                        //    footer: ko.observable(msgRegistrationCompleted.footer)
                        //};
                        message.header(msgRegistrationCompleted.header);
                        message.body(msgRegistrationCompleted.body.replace('<SalesRepName>', registerPersonalInfo.salesExecutiveContact()));
                        message.footer(msgRegistrationCompleted.footer);
                        currentStep('');
                        newStep('userModule/_confirmMessage.html');

                        ////alert.error(data.Messages[0].Message + "   " + data.Messages[0].ExceptionStackTrace, "Registration");
                        //alert.warning(data.Messages[0].Message, pageHeader());

                        //isRegistrationComplete(true);
                        //clear();
                        //showChangePassword(true);
                    }
                    session.isBusy(false);
                })
                .fail(Failed);
        }

        function createAccount()
        {
            //registerPersonalInfo.update();
            var agreed = registerPersonalInfo.agreeTerms();
            var agreedlla = agreeLLA();

            if (vm.PersonalvalidationErrors().length > 0)
            {
                vm.PersonalvalidationErrors.showAllMessages();
                return;
            }
            if (!session.isBusy())
                session.isBusy(true);
            else
                return;

            var url = config.registerUserUrl;
            var postLLAUrl = config.postLLAUrl;

            var request = {

                "EmailAddress": registerAccountInfo.email,
                "Password": registerPersonalInfo.password,
                "FirstName": registerPersonalInfo.firstName,
                "LastName": registerPersonalInfo.lastName,
                "CompanyName": registerPersonalInfo.companyName,
                "Address1": registerPersonalInfo.streetAddress,
                "Address2": registerPersonalInfo.suitNumber,
                "City": registerPersonalInfo.city,
                "State": registerPersonalInfo.state,
                "Zip": registerPersonalInfo.zipCode,
                "County": registerPersonalInfo.countyName,
                "SaleExecutive": registerPersonalInfo.salesExecutive,//"FD3C9F6C-914D-48B8-B97C-7A0A0295366A",
                "Fips": registerPersonalInfo.county,
                "Phone": unmaskPhoneNumber(registerPersonalInfo.workPhone),
                "CellPhone": unmaskPhoneNumber(registerPersonalInfo.cellPhone),
                //"SecretQuestion": registerAccountInfo.secretQuestion,
                //"SecretAnswer": registerAccountInfo.secretAnswer,
                "OccupationId": registerPersonalInfo.occupation,
                "PhoneType": "",
                "Birthday": dateofBirth,
                "IsAgree": agreeLLA(),
                "IsNewUser": IsNewUserRegistration()

            };

            https.post(url, request)
                .then(function (data)
                {
                    session.isBusy(false);
                    $("body").scrollTop(0);
                    if ((data.HasErrors || data.HasValidationErrors) && data.Messages.length > 0)
                    {
                        for (var i = 0; i < data.Messages.length; i++)
                        {
                            alert.error(data.Messages[i].Message, "User Registration");
                        }
                    }
                    //if (data.HasErrors ) {
                    //    alert.error(data.Messages[0].Message, "User Registration");
                    //}
                    else if (data.HasErrors == false)
                    {


                        if (agreeLLA() == true)
                        {
                            //saveLLADetails();
                            alert.success("User successfully registered & Customer acceptance successfully recorded");
                        }
                        else
                        {
                            alert.success("User successfully registered", "Registration");
                        }

                        isRegistrationComplete(true);
                        //message = {
                        //    header: ko.observable(msgRegistrationCompleted.header),
                        //    body: ko.observable(msgRegistrationCompleted.body.replace('<SalesRepName>', registerPersonalInfo.salesExecutiveContact())),
                        //    footer: ko.observable(msgRegistrationCompleted.footer)
                        //};

                        message.header(msgRegistrationCompleted.header);
                        message.body(msgRegistrationCompleted.body.replace('<SalesRepName>', registerPersonalInfo.salesExecutiveContact()));
                        message.footer(msgRegistrationCompleted.footer);
                        //newStep('userModule/_registerStep3.html');
                        currentStep('');
                        newStep('userModule/_confirmMessage.html');
                        //clear();
                    }
                    else
                    {
                        //alert.error(data.Messages[0].Message + "   " + data.Messages[0].ExceptionStackTrace, "Registration");
                        alert.warning("Server Is Busy. Please Try Again", "Registration");
                    }
                    session.isBusy(false);
                })
                .fail(Failed);
        }

        function saveLLADetails()
        {
            var agreeStatus = (agreeLLA() == true) ? 'Agree' : 'NotAgree';
            var request = {

                "FirstName": registerPersonalInfo.firstName,
                "LastName": registerPersonalInfo.lastName,
                "Username": registerAccountInfo.email,
                "Company": registerPersonalInfo.companyName,
                "Address": registerPersonalInfo.suitNumber() + ' ' + registerPersonalInfo.streetAddress(),
                //"Address2": registerPersonalInfo.suitNumber,
                "City": registerPersonalInfo.city,
                "State": registerPersonalInfo.state,
                "Zip": registerPersonalInfo.zipCode,
                //"County": registerPersonalInfo.countyName,
                "Phone": unmaskPhoneNumber(registerPersonalInfo.workPhone),
                "AgreeStatus": agreeStatus

            };

            return https.post(config.postLLAUrl, request)
                .done(function (result)
                {
                    if (result.HasErrors != false)
                    {
                        console.log("Something went wrong while saving the LLA details.");
                    }
                    else
                    {
                        console.log("LLA details saved succesfully");
                        alert.success("Customer acceptance successfully recorded");
                    }
                })
                .fail(function ()
                {
                    console.log("Couldn't save LLA details");
                });
        }

        function Failed(error)
        {
            $('a.close').trigger("click");
            session.isBusy(false);
            console.log(error);
            alert.error(error.statusText);
            console.log(error.statusText);
        }

        function register()
        {

            if (vm.PersonalvalidationErrors().length > 0)
            {
                vm.PersonalvalidationErrors.showAllMessages();
                return;
            }


        }

        function nextStep()
        {
            console.log('nextStep clicked');

            console.log(registerAccountInfo);
            console.log(vm.AccountvalidationErrors());

            if (vm.AccountvalidationErrors().length > 0)
            {
                vm.AccountvalidationErrors.showAllMessages();
                return;
            }

            var val = vm.registerAccountInfo.email();

            if (val != undefined && val != '')
            {
                if (step1Valid())
                {
                    currentStep('userModule/_registerStepCompleteMessage.html');
                    setPlugins();
                    return;
                }
                var url = config.userExistUrl;
                session.isBusy(true);

                Q.when(https.get(url, {
                    username: val,
                    key: captchaResponse,
                    format: 'json'
                }, null, null, null, null, true)
                    .then(function (data)
                    {
                        session.isBusy(false);
                        if (data.HasErrors == true && data.Messages.length > 0)
                        {
                            for (var i = 0; i < data.Messages.length; i++)
                            {
                                alert.error(data.Messages[i].Message, "User Registration");
                            }
                        }
                        else if (data.IsValidRequest == false)
                        {
                            alert.error("Please select \"I'm not a robot\".");
                            try
                            {
                                grecaptcha.reset();
                            }
                            catch (ex)
                            {
                                //     setupCaptcha();
                            }

                            isNotRobotValidated(true);
                        }
                        else if (data.SentEmail == true)
                        {
                            //message = {
                            //    header: ko.observable(msgAfterFirstStep.header),
                            //    body: ko.observable(msgAfterFirstStep.body),
                            //    footer: ko.observable(msgAfterFirstStep.footer)
                            //};

                            message.header(msgAfterFirstStep.header);
                            message.body(msgAfterFirstStep.body);
                            message.footer(msgAfterFirstStep.footer);

                            session.showLogin(false);
                            currentStep('userModule/_confirmMessage.html');
                        }
                        //else if (data.IsUserExists == true) {
                        //    alert.error(msgAlreadyRegistered.body);
                        //    registerAccountInfo.email('');
                        //    registerAccountInfo.confirmEmail('');
                        //    return;
                        //}
                        //else //new user
                        //{
                        //    step1Valid(true);
                        //    currentStep('userModule/_registerStep2.html');
                        //    setPlugins();
                        //    return;
                        //}

                    }).fail(Failed));
            }
        }

        function attachMask()
        {

        }

        function prevStep()
        {
            console.log('prevStep clicked');
            session.showLogin(true);
            currentStep('userModule/_registerStep1.html');
            setPlugins();
        }

        function login()
        {
            if (session.useOktaAuthentication()) {
                shell.logout();
                window.location = window.location.origin;
            }
            else {
                clear();
                window.location = window.location.origin;
                //router.navigate('#login', { replace: false, trigger: true });
            }
        }

        function goBack(complete)
        {
            router.navigateBack();
        }

        function validateChangePasswordRequest(request)
        {
            var isvalid = false;
            if (request == null || request == undefined)
                isvalid = false;
            else if ((vm.ChangePasswordValidationErrors().length > 0) && (vm.ChangePasswordValidationErrors().length > 0))
            {
                isvalid = false;
            }
            else
            {
                isvalid = true;
            }

            return isvalid;
        }

        function submitNewPassword()
        {
            var url = config.changePasswordOnBrandRegistrationUrl;
            var request = {
                "OldPassword": passwordModel.currentPassword(),
                "NewPassword": passwordModel.newPassword(),
                "ConfirmPassword": passwordModel.confirmPassword(),
                "Token": token(),
                "UserId": currentUserId
            };

            var validRequest = validateChangePasswordRequest(request);
            if (validRequest == true)
            {

                https.post(url, request)
                    .then(function (data)
                    {
                        if (data != undefined && data != null && data.HasErrors == true)
                        {
                            var msgSuccess = "";
                            var msgError = "";

                            for (var i = 0; i < data.Messages.length; i++)
                            {
                                if (data.Messages[i].Message != "" && data.Messages[i].Level == 500)
                                {
                                    if (msgError.trim().indexOf(data.Messages[i].Message) == -1)
                                    {
                                        msgError = msgError + data.Messages[i].Message + "\n";
                                    }
                                }
                                else
                                {
                                    msgSuccess = msgSuccess + data.Messages[i].Message + "\n";
                                }
                            }
                            alert.warning(msgError);
                        }


                        $('a.close').click();
                        //message = {
                        //    header: ko.observable(msgPasswordUpdated.header),
                        //    body: ko.observable(msgPasswordUpdated.body),
                        //    footer: ko.observable(msgPasswordUpdated.footer)
                        //};

                        message.header(msgPasswordUpdated.header);
                        message.body(msgPasswordUpdated.body);
                        message.footer(msgPasswordUpdated.footer);

                        session.showLogin(false);
                        currentStep('');
                        newStep('userModule/_confirmMessage.html');
                    })
                    .fail(function (data)
                    {
                        $('a.close').click();
                        var errmessage = toErrorString(data);
                        alert.error(errmessage);
                        passwordModel.reset();
                        $('.confrmPswd').trigger('blur');
                    });
            }
            else
            {
                vm.ChangePasswordValidationErrors.showAllMessages();
            }
        }

        function toErrorString(data)
        {
            var errors, items;
            if (data.responseJSON.ModelState)
            {
                for (var key in data.responseJSON.ModelState)
                {
                    items = data.responseJSON.ModelState[key];
                    if (items.length)
                    {
                        errors = items.join(",");
                    }
                }
            }

            if (errors)
                return errors;

            if (data && data.responseJSON && data.responseJSON.Message)
            {
                errors = data.responseJSON.Message;
                return errors;
            }

            if (errors === undefined)
            {
                errors = "An error occurred when trying to process your request";
            }

            return errors;
        }
    });



define('models/commonDialog',["knockout", 'jquery', 'plugins/router'],
    function (ko, $, router) {

        function commonDialog(DialogId) {
            var self = this;

            self.viewName = "_commonDialog.html";
            self.title = ko.observable("");
            self.ModelId = "commonDialog";
            self.ModalId = ko.observable(DialogId);
            self.message = ko.observable("");
            self.messagehtml = ko.observable("");
            self.orderId = ko.observable("");
            self.showSpText = ko.observable(false);
            self.showIcText = ko.observable(false);
            self.showManualText = ko.observable(false);
            self.IsOrderDialog = ko.observable(false);
            self.IntendedRecepients = ko.observable([]);
            self.IsOrderCancelDialog = ko.observable(false);

            self.update = function (data) {
                if (data) {
                    self.title(data.title());
                    self.message(data.message());
                    self.ModalId(data.ModalId());
                    self.orderId(data.OrderId());
                    self.showSpText(data.showSpText);
                    self.showIcText(data.showIcText);
                    self.showManualText(data.showManualText);
                }
            }

            self.reset = function () {
                self.title("");
                self.message("");
                self.messagehtml("");
                self.ModalId("");
                self.orderId("");
                self.showSpText(false);
                self.showIcText = ko.observable(false);
                self.showManualText = ko.observable(false);
                //self.IsOrderCancelDialog(false);
                //self.IsOrderDialog(false);
            }

            self.show = function(title, message)
            {
                console.log('commonDialong show called ' + self.ModalId());
                self.title(title);
                self.message(message);
                $('#' + self.ModalId()).modal('show');
                $('#btnCommonDialogClose').eq(0).focus();
            }

            self.closeDialog = function()
            {
                $('body').attr('style', 'padding-right:0!important');
                if (self.IsOrderDialog() || self.IsOrderCancelDialog())
                router.navigate("/#orders");
            }
        }
        return commonDialog;

    });
